

export class EmailDataReminderDataInput implements IEmailDataReminderDataInput {
    /** The mobile number with 50 character */
    emailId!: string;

    constructor(data?: IEmailDataReminderDataInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.emailId = _data["emailId"];
        }
    }

    static fromJS(data: any): EmailDataReminderDataInput {
        data = typeof data === 'object' ? data : {};
        let result = new EmailDataReminderDataInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["emailId"] = this.emailId;
        return data; 
    }
}

export interface IEmailDataReminderDataInput {
    /** The mobile number with 50 character */
    emailId: string;
}

