import { Injectable } from '@angular/core';
import { SharedService, ToasterService } from '../services';
import { Observable, of, throwError as _observableThrow } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class CommonFunctions {

    constructor(private sharedService: SharedService, private toasterService: ToasterService) { }

    throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
        if (result !== null && result !== undefined)
            return _observableThrow(result);
        else
            return _observableThrow(new ApiException(message, status, response, headers, null));
    }

    blobToText(blob: any): Observable<string> {
        return new Observable<string>((observer: any) => {
            if (!blob) {
                observer.next("");
                observer.complete();
            } else {
                let reader = new FileReader();
                reader.onload = event => {
                    observer.next((<any>event.target).result);
                    observer.complete();
                };
                reader.readAsText(blob);
            }
        }).pipe(
            mergeMap(_responseText => {
                if (_responseText) {
                    let result = JSON.parse(_responseText);
                    if (result?.status != 1) {
                        this.sharedService.setApiCall(false);
                        this.toasterService.showApiValidationError(result);
                    }
                }
                return of(_responseText);
            })
        );
    }
}

export class ApiException extends Error {
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}


