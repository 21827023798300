import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { RoutePathAddInput } from '../models/route-path-add-input.model';
import { RoutePathAddOutput } from '../models/route-path-add-output.model';
import { RoutePathSelectActiveOutput } from '../models/route-path-select-active-output.model';
import { RoutePathSelectAllOutput } from '../models/route-path-select-all-output.model';
import { RoutePathSelectByIdOutput } from '../models/route-path-select-by-id-output.model';
import { RoutePathStatusInput } from '../models/route-path-status-input.model';
import { RoutePathStatusOutput } from '../models/route-path-status-output.model';
import { RoutePathUpdateInput } from '../models/route-path-update-input.model';
import { RoutePathUpdateOutput } from '../models/route-path-update-output.model';
import { SelectRouteByCompanyIdBranchIdInput } from '../models/select-route-by-company-id-branch-id-input.model';
import { SelectRouteByCompanyIdBranchIdOutput } from '../models/select-route-by-company-id-branch-id-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class RoutePathServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3001/api/v1";
    }

    /**
     * @return Route path added successfully.
     */
    add(body: RoutePathAddInput): Observable<RoutePathAddOutput> {
        let url_ = this.baseUrl + "/RoutePath/Add";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAdd(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAdd(<any>response_);
                } catch (e) {
                    return <Observable<RoutePathAddOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<RoutePathAddOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAdd(response: HttpResponseBase): Observable<RoutePathAddOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = RoutePathAddOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<RoutePathAddOutput>(<any>null);
    }

    /**
     * @return Route path updated successfully.
     */
    update(body: RoutePathUpdateInput): Observable<RoutePathUpdateOutput> {
        let url_ = this.baseUrl + "/RoutePath/Update";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUpdate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdate(<any>response_);
                } catch (e) {
                    return <Observable<RoutePathUpdateOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<RoutePathUpdateOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdate(response: HttpResponseBase): Observable<RoutePathUpdateOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = RoutePathUpdateOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<RoutePathUpdateOutput>(<any>null);
    }

    /**
     * @return Route path status updated successfully.
     */
    status(body: RoutePathStatusInput): Observable<RoutePathStatusOutput> {
        let url_ = this.baseUrl + "/RoutePath/Status";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatus(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatus(<any>response_);
                } catch (e) {
                    return <Observable<RoutePathStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<RoutePathStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatus(response: HttpResponseBase): Observable<RoutePathStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = RoutePathStatusOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<RoutePathStatusOutput>(<any>null);
    }

    /**
     * @return All route path selected successfully.
     */
    selectAll(): Observable<RoutePathSelectAllOutput> {
        let url_ = this.baseUrl + "/RoutePath/SelectAll";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAll(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAll(<any>response_);
                } catch (e) {
                    return <Observable<RoutePathSelectAllOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<RoutePathSelectAllOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAll(response: HttpResponseBase): Observable<RoutePathSelectAllOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = RoutePathSelectAllOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<RoutePathSelectAllOutput>(<any>null);
    }

    /**
     * @return Active route path selected successfully.
     */
    selectActive(): Observable<RoutePathSelectActiveOutput> {
        let url_ = this.baseUrl + "/RoutePath/SelectActive";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectActive(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActive(<any>response_);
                } catch (e) {
                    return <Observable<RoutePathSelectActiveOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<RoutePathSelectActiveOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActive(response: HttpResponseBase): Observable<RoutePathSelectActiveOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = RoutePathSelectActiveOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<RoutePathSelectActiveOutput>(<any>null);
    }

    /**
     * @return Route path selected successfully.
     */
    selectById(routePathId: number): Observable<RoutePathSelectByIdOutput> {
        let url_ = this.baseUrl + "/RoutePath/SelectById/{routePathId}";
        if (routePathId === undefined || routePathId === null)
            throw new Error("The parameter 'routePathId' must be defined.");
        url_ = url_.replace("{routePathId}", encodeURIComponent("" + routePathId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectById(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectById(<any>response_);
                } catch (e) {
                    return <Observable<RoutePathSelectByIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<RoutePathSelectByIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectById(response: HttpResponseBase): Observable<RoutePathSelectByIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = RoutePathSelectByIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<RoutePathSelectByIdOutput>(<any>null);
    }

    /**
     * @return Route path selected successfully.
     */
    selectRouteByCompanyIdBranchId(body: SelectRouteByCompanyIdBranchIdInput): Observable<SelectRouteByCompanyIdBranchIdOutput> {
        let url_ = this.baseUrl + "/RoutePath/SelectRouteByCompanyIdBranchId";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectRouteByCompanyIdBranchId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectRouteByCompanyIdBranchId(<any>response_);
                } catch (e) {
                    return <Observable<SelectRouteByCompanyIdBranchIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectRouteByCompanyIdBranchIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectRouteByCompanyIdBranchId(response: HttpResponseBase): Observable<SelectRouteByCompanyIdBranchIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SelectRouteByCompanyIdBranchIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectRouteByCompanyIdBranchIdOutput>(<any>null);
    }
}
