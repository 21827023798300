import { VehicleGroupIdDataInput } from './vehicle-group-id-data-input.model';


export class SelectByVehicleGroupIdInput implements ISelectByVehicleGroupIdInput {
    vehicleGroupIdData!: VehicleGroupIdDataInput[];

    constructor(data?: ISelectByVehicleGroupIdInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["vehicleGroupIdData"])) {
                this.vehicleGroupIdData = [] as any;
                for (let item of _data["vehicleGroupIdData"])
                    this.vehicleGroupIdData!.push(VehicleGroupIdDataInput.fromJS(item));
            }
        }
    }

    static fromJS(data: any): SelectByVehicleGroupIdInput {
        data = typeof data === 'object' ? data : {};
        let result = new SelectByVehicleGroupIdInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.vehicleGroupIdData)) {
            data["vehicleGroupIdData"] = [];
            for (let item of this.vehicleGroupIdData)
                data["vehicleGroupIdData"].push(item.toJSON());
        }
        return data; 
    }
}

export interface ISelectByVehicleGroupIdInput {
    vehicleGroupIdData: VehicleGroupIdDataInput[];
}

