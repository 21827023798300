import { TourTripDataInput } from './tour-trip-data-input.model';


export class TourAllocationUpdateInput implements ITourAllocationUpdateInput {
    /** The auto generated id from tour allocation master */
    tourAllocationId!: number;
    /** The auto generated id from branch details master */
    branchDetailId!: number;
    /** The auto generated id from branch tour master */
    tourId!: number;
    /** The auto generated id from vehicle details master */
    vehicleDetailsId!: number;
    /** The tour schedule type  should be Relative, Fixed or Automatic */
    tourScheduleType!: string;
    /** The tour schedule should be Daily,Weekly,Monthly */
    schedule!: string;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    sunday!: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    monday!: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    tuesday!: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    wednesday!: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    thursday!: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    friday!: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    saturday!: number;
    /** The days */
    days!: number;
    /** The  trip start time 20 character limit */
    tripStartTime!: string;
    /** The valid from 20 character limit */
    validFrom!: string;
    /** The valid to 20 character limit */
    validTo!: string;
    tourTripData!: TourTripDataInput[];

    constructor(data?: ITourAllocationUpdateInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.tourAllocationId = _data["tourAllocationId"];
            this.branchDetailId = _data["branchDetailId"];
            this.tourId = _data["tourId"];
            this.vehicleDetailsId = _data["vehicleDetailsId"];
            this.tourScheduleType = _data["tourScheduleType"];
            this.schedule = _data["schedule"];
            this.sunday = _data["sunday"];
            this.monday = _data["monday"];
            this.tuesday = _data["tuesday"];
            this.wednesday = _data["wednesday"];
            this.thursday = _data["thursday"];
            this.friday = _data["friday"];
            this.saturday = _data["saturday"];
            this.days = _data["days"];
            this.tripStartTime = _data["tripStartTime"];
            this.validFrom = _data["validFrom"];
            this.validTo = _data["validTo"];
            if (Array.isArray(_data["tourTripData"])) {
                this.tourTripData = [] as any;
                for (let item of _data["tourTripData"])
                    this.tourTripData!.push(TourTripDataInput.fromJS(item));
            }
        }
    }

    static fromJS(data: any): TourAllocationUpdateInput {
        data = typeof data === 'object' ? data : {};
        let result = new TourAllocationUpdateInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["tourAllocationId"] = this.tourAllocationId;
        data["branchDetailId"] = this.branchDetailId;
        data["tourId"] = this.tourId;
        data["vehicleDetailsId"] = this.vehicleDetailsId;
        data["tourScheduleType"] = this.tourScheduleType;
        data["schedule"] = this.schedule;
        data["sunday"] = this.sunday;
        data["monday"] = this.monday;
        data["tuesday"] = this.tuesday;
        data["wednesday"] = this.wednesday;
        data["thursday"] = this.thursday;
        data["friday"] = this.friday;
        data["saturday"] = this.saturday;
        data["days"] = this.days;
        data["tripStartTime"] = this.tripStartTime;
        data["validFrom"] = this.validFrom;
        data["validTo"] = this.validTo;
        if (Array.isArray(this.tourTripData)) {
            data["tourTripData"] = [];
            for (let item of this.tourTripData)
                data["tourTripData"].push(item.toJSON());
        }
        return data; 
    }
}

export interface ITourAllocationUpdateInput {
    /** The auto generated id from tour allocation master */
    tourAllocationId: number;
    /** The auto generated id from branch details master */
    branchDetailId: number;
    /** The auto generated id from branch tour master */
    tourId: number;
    /** The auto generated id from vehicle details master */
    vehicleDetailsId: number;
    /** The tour schedule type  should be Relative, Fixed or Automatic */
    tourScheduleType: string;
    /** The tour schedule should be Daily,Weekly,Monthly */
    schedule: string;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    sunday: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    monday: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    tuesday: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    wednesday: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    thursday: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    friday: number;
    /** The activation for alert group day wise 0 for unselected, 1 for selected */
    saturday: number;
    /** The days */
    days: number;
    /** The  trip start time 20 character limit */
    tripStartTime: string;
    /** The valid from 20 character limit */
    validFrom: string;
    /** The valid to 20 character limit */
    validTo: string;
    tourTripData: TourTripDataInput[];
}

