import { CountryWithStateOutputData } from './country-with-state-output-data.model';


export class CountryStateOutput implements ICountryStateOutput {
    /** Response status */
    status!: number;
    message!: string;
    data!: CountryWithStateOutputData[];

    constructor(data?: ICountryStateOutput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.status = _data["status"];
            this.message = _data["message"];
            if (Array.isArray(_data["data"])) {
                this.data = [] as any;
                for (let item of _data["data"])
                    this.data!.push(CountryWithStateOutputData.fromJS(item));
            }
        }
    }

    static fromJS(data: any): CountryStateOutput {
        data = typeof data === 'object' ? data : {};
        let result = new CountryStateOutput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["status"] = this.status;
        data["message"] = this.message;
        if (Array.isArray(this.data)) {
            data["data"] = [];
            for (let item of this.data)
                data["data"].push(item.toJSON());
        }
        return data; 
    }
}

export interface ICountryStateOutput {
    /** Response status */
    status: number;
    message: string;
    data: CountryWithStateOutputData[];
}

