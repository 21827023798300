import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { TemperatureAddInput } from '../models/temperature-add-input.model';
import { TemperatureAddOutput } from '../models/temperature-add-output.model';
import { TemperatureCalibrationAddInput } from '../models/temperature-calibration-add-input.model';
import { TemperatureCalibrationAddOutput } from '../models/temperature-calibration-add-output.model';
import { TemperatureCalibrationSelectAllOutput } from '../models/temperature-calibration-select-all-output.model';
import { TemperatureCalibrationSelectByIdOutput } from '../models/temperature-calibration-select-by-id-output.model';
import { TemperatureCalibrationStatusInput } from '../models/temperature-calibration-status-input.model';
import { TemperatureCalibrationStatusOutput } from '../models/temperature-calibration-status-output.model';
import { TemperatureCalibrationUpdateInput } from '../models/temperature-calibration-update-input.model';
import { TemperatureCalibrationUpdateOutput } from '../models/temperature-calibration-update-output.model';
import { TemperatureSelectActiveOutput } from '../models/temperature-select-active-output.model';
import { TemperatureSelectAllOutput } from '../models/temperature-select-all-output.model';
import { TemperatureSelectByIdOutput } from '../models/temperature-select-by-id-output.model';
import { TemperatureStatusInput } from '../models/temperature-status-input.model';
import { TemperatureStatusOutput } from '../models/temperature-status-output.model';
import { TemperatureUpdateInput } from '../models/temperature-update-input.model';
import { TemperatureUpdateOutput } from '../models/temperature-update-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class TemperatureCalibrationServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3001/api/v1";
    }

    /**
     * @return Temperature calibration added successfully.
     */
    add(body: TemperatureCalibrationAddInput): Observable<TemperatureCalibrationAddOutput> {
        let url_ = this.baseUrl + "/TemperatureCalibration/Add";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAdd(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAdd(<any>response_);
                } catch (e) {
                    return <Observable<TemperatureCalibrationAddOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<TemperatureCalibrationAddOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAdd(response: HttpResponseBase): Observable<TemperatureCalibrationAddOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = TemperatureCalibrationAddOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TemperatureCalibrationAddOutput>(<any>null);
    }

    /**
     * @return Temperature calibration updated successfully.
     */
    update(body: TemperatureCalibrationUpdateInput): Observable<TemperatureCalibrationUpdateOutput> {
        let url_ = this.baseUrl + "/TemperatureCalibration/Update";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUpdate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdate(<any>response_);
                } catch (e) {
                    return <Observable<TemperatureCalibrationUpdateOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<TemperatureCalibrationUpdateOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdate(response: HttpResponseBase): Observable<TemperatureCalibrationUpdateOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = TemperatureCalibrationUpdateOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TemperatureCalibrationUpdateOutput>(<any>null);
    }

    /**
     * @return Temperature calibration status updated successfully.
     */
    status(body: TemperatureCalibrationStatusInput): Observable<TemperatureCalibrationStatusOutput> {
        let url_ = this.baseUrl + "/TemperatureCalibration/Status";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatus(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatus(<any>response_);
                } catch (e) {
                    return <Observable<TemperatureCalibrationStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<TemperatureCalibrationStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatus(response: HttpResponseBase): Observable<TemperatureCalibrationStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = TemperatureCalibrationStatusOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TemperatureCalibrationStatusOutput>(<any>null);
    }

    /**
     * @return All temperature calibration  selected successfully.
     */
    selectAll(): Observable<TemperatureCalibrationSelectAllOutput> {
        let url_ = this.baseUrl + "/TemperatureCalibration/SelectAll";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAll(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAll(<any>response_);
                } catch (e) {
                    return <Observable<TemperatureCalibrationSelectAllOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<TemperatureCalibrationSelectAllOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAll(response: HttpResponseBase): Observable<TemperatureCalibrationSelectAllOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = TemperatureCalibrationSelectAllOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TemperatureCalibrationSelectAllOutput>(<any>null);
    }

    /**
     * @return Temperature calibration selected successfully.
     */
    selectById(temperatureCalibrationId: number): Observable<TemperatureCalibrationSelectByIdOutput> {
        let url_ = this.baseUrl + "/TemperatureCalibration/SelectById/{temperatureCalibrationId}";
        if (temperatureCalibrationId === undefined || temperatureCalibrationId === null)
            throw new Error("The parameter 'temperatureCalibrationId' must be defined.");
        url_ = url_.replace("{temperatureCalibrationId}", encodeURIComponent("" + temperatureCalibrationId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectById(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectById(<any>response_);
                } catch (e) {
                    return <Observable<TemperatureCalibrationSelectByIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<TemperatureCalibrationSelectByIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectById(response: HttpResponseBase): Observable<TemperatureCalibrationSelectByIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = TemperatureCalibrationSelectByIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TemperatureCalibrationSelectByIdOutput>(<any>null);
    }

    /**
     * @return Temperature added successfully.
     */
    addSelectedTemperature(body: TemperatureAddInput): Observable<TemperatureAddOutput> {
        let url_ = this.baseUrl + "/TemperatureCalibration/AddSelectedTemperature";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAddSelectedTemperature(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAddSelectedTemperature(<any>response_);
                } catch (e) {
                    return <Observable<TemperatureAddOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<TemperatureAddOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAddSelectedTemperature(response: HttpResponseBase): Observable<TemperatureAddOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = TemperatureAddOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TemperatureAddOutput>(<any>null);
    }

    /**
     * @return Temperature added successfully.
     */
    updateSelectedTemperature(body: TemperatureUpdateInput): Observable<TemperatureUpdateOutput> {
        let url_ = this.baseUrl + "/TemperatureCalibration/UpdateSelectedTemperature";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUpdateSelectedTemperature(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdateSelectedTemperature(<any>response_);
                } catch (e) {
                    return <Observable<TemperatureUpdateOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<TemperatureUpdateOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdateSelectedTemperature(response: HttpResponseBase): Observable<TemperatureUpdateOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = TemperatureUpdateOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TemperatureUpdateOutput>(<any>null);
    }

    /**
     * @return Temperature status updated successfully.
     */
    statusSelectedTemperature(body: TemperatureStatusInput): Observable<TemperatureStatusOutput> {
        let url_ = this.baseUrl + "/TemperatureCalibration/StatusSelectedTemperature";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatusSelectedTemperature(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatusSelectedTemperature(<any>response_);
                } catch (e) {
                    return <Observable<TemperatureStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<TemperatureStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatusSelectedTemperature(response: HttpResponseBase): Observable<TemperatureStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = TemperatureStatusOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TemperatureStatusOutput>(<any>null);
    }

    /**
     * @return All Temperature  selected successfully.
     */
    selectAllSelectedTemperature(temperatureCalibrationId: number): Observable<TemperatureSelectAllOutput> {
        let url_ = this.baseUrl + "/TemperatureCalibration/SelectAllSelectedTemperature/{temperatureCalibrationId}";
        if (temperatureCalibrationId === undefined || temperatureCalibrationId === null)
            throw new Error("The parameter 'temperatureCalibrationId' must be defined.");
        url_ = url_.replace("{temperatureCalibrationId}", encodeURIComponent("" + temperatureCalibrationId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAllSelectedTemperature(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAllSelectedTemperature(<any>response_);
                } catch (e) {
                    return <Observable<TemperatureSelectAllOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<TemperatureSelectAllOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAllSelectedTemperature(response: HttpResponseBase): Observable<TemperatureSelectAllOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = TemperatureSelectAllOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TemperatureSelectAllOutput>(<any>null);
    }

    /**
     * @return Active Temperature selected successfully.
     */
    selectActiveSelectedTemperature(temperatureCalibrationId: number): Observable<TemperatureSelectActiveOutput> {
        let url_ = this.baseUrl + "/TemperatureCalibration/SelectActiveSelectedTemperature/{temperatureCalibrationId}";
        if (temperatureCalibrationId === undefined || temperatureCalibrationId === null)
            throw new Error("The parameter 'temperatureCalibrationId' must be defined.");
        url_ = url_.replace("{temperatureCalibrationId}", encodeURIComponent("" + temperatureCalibrationId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectActiveSelectedTemperature(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActiveSelectedTemperature(<any>response_);
                } catch (e) {
                    return <Observable<TemperatureSelectActiveOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<TemperatureSelectActiveOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActiveSelectedTemperature(response: HttpResponseBase): Observable<TemperatureSelectActiveOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = TemperatureSelectActiveOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TemperatureSelectActiveOutput>(<any>null);
    }

    /**
     * @return Temperature selected successfully.
     */
    selectTemperatureById(selectedTemperatureId: number): Observable<TemperatureSelectByIdOutput> {
        let url_ = this.baseUrl + "/TemperatureCalibration/SelectTemperatureById/{selectedTemperatureId}";
        if (selectedTemperatureId === undefined || selectedTemperatureId === null)
            throw new Error("The parameter 'selectedTemperatureId' must be defined.");
        url_ = url_.replace("{selectedTemperatureId}", encodeURIComponent("" + selectedTemperatureId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectTemperatureById(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectTemperatureById(<any>response_);
                } catch (e) {
                    return <Observable<TemperatureSelectByIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<TemperatureSelectByIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectTemperatureById(response: HttpResponseBase): Observable<TemperatureSelectByIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = TemperatureSelectByIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<TemperatureSelectByIdOutput>(<any>null);
    }
}
