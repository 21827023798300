

export class CompanyBranchDataOutput implements ICompanyBranchDataOutput {
    /** The Reference id of the tbl_branch_detail table */
    branch_detail_id!: number;
    /** The branch name with 255 character limit */
    branch!: string;

    constructor(data?: ICompanyBranchDataOutput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.branch_detail_id = _data["branch_detail_id"];
            this.branch = _data["branch"];
        }
    }

    static fromJS(data: any): CompanyBranchDataOutput {
        data = typeof data === 'object' ? data : {};
        let result = new CompanyBranchDataOutput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["branch_detail_id"] = this.branch_detail_id;
        data["branch"] = this.branch;
        return data; 
    }
}

export interface ICompanyBranchDataOutput {
    /** The Reference id of the tbl_branch_detail table */
    branch_detail_id: number;
    /** The branch name with 255 character limit */
    branch: string;
}

