

export class AddMainMenuInput implements IAddMainMenuInput {
    /** The main menu name with 100 character limit */
    menuName!: string;
    /** The main menu icon with 100 character limit */
    matIcon!: string;
    /** The sub menu icon with 2048 character limit */
    menuURL!: string;
    /** The sub menu type must be "OVERVIEW" or "DETAIL" */
    screenType!: string;
    /** The index_number */
    indexNumber!: number;

    constructor(data?: IAddMainMenuInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.menuName = _data["menuName"];
            this.matIcon = _data["matIcon"];
            this.menuURL = _data["menuURL"];
            this.screenType = _data["screenType"];
            this.indexNumber = _data["indexNumber"];
        }
    }

    static fromJS(data: any): AddMainMenuInput {
        data = typeof data === 'object' ? data : {};
        let result = new AddMainMenuInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["menuName"] = this.menuName;
        data["matIcon"] = this.matIcon;
        data["menuURL"] = this.menuURL;
        data["screenType"] = this.screenType;
        data["indexNumber"] = this.indexNumber;
        return data; 
    }
}

export interface IAddMainMenuInput {
    /** The main menu name with 100 character limit */
    menuName: string;
    /** The main menu icon with 100 character limit */
    matIcon: string;
    /** The sub menu icon with 2048 character limit */
    menuURL: string;
    /** The sub menu type must be "OVERVIEW" or "DETAIL" */
    screenType: string;
    /** The index_number */
    indexNumber: number;
}

