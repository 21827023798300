

export class TyreBrandAddInput implements ITyreBrandAddInput {
    /** The tyre brand name with 255 character limit */
    tyreBrandName!: string;

    constructor(data?: ITyreBrandAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.tyreBrandName = _data["tyreBrandName"];
        }
    }

    static fromJS(data: any): TyreBrandAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new TyreBrandAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["tyreBrandName"] = this.tyreBrandName;
        return data; 
    }
}

export interface ITyreBrandAddInput {
    /** The tyre brand name with 255 character limit */
    tyreBrandName: string;
}

