import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { CustomizeToolTipAddInput } from '../models/customize-tool-tip-add-input.model';
import { CustomizeToolTipAddOutput } from '../models/customize-tool-tip-add-output.model';
import { CustomizeToolTipSelectAllOutput } from '../models/customize-tool-tip-select-all-output.model';
import { CustomizeToolTipSelectByIdOutput } from '../models/customize-tool-tip-select-by-id-output.model';
import { CustomizeToolTipStatusInput } from '../models/customize-tool-tip-status-input.model';
import { CustomizeToolTipStatusOutput } from '../models/customize-tool-tip-status-output.model';
import { CustomizeToolTipUpdateInput } from '../models/customize-tool-tip-update-input.model';
import { CustomizeToolTipUpdateOutput } from '../models/customize-tool-tip-update-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class CustomizeToolTipServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3001/api/v1";
    }

    /**
     * @return Customize tool tip added successfully.
     */
    add(body: CustomizeToolTipAddInput): Observable<CustomizeToolTipAddOutput> {
        let url_ = this.baseUrl + "/CustomizeToolTip/Add";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAdd(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAdd(<any>response_);
                } catch (e) {
                    return <Observable<CustomizeToolTipAddOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CustomizeToolTipAddOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAdd(response: HttpResponseBase): Observable<CustomizeToolTipAddOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = CustomizeToolTipAddOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CustomizeToolTipAddOutput>(<any>null);
    }

    /**
     * @return Customize tool tip updated successfully.
     */
    update(body: CustomizeToolTipUpdateInput): Observable<CustomizeToolTipUpdateOutput> {
        let url_ = this.baseUrl + "/CustomizeToolTip/Update";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUpdate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdate(<any>response_);
                } catch (e) {
                    return <Observable<CustomizeToolTipUpdateOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CustomizeToolTipUpdateOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdate(response: HttpResponseBase): Observable<CustomizeToolTipUpdateOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = CustomizeToolTipUpdateOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CustomizeToolTipUpdateOutput>(<any>null);
    }

    /**
     * @return Customize tool tip status updated successfully.
     */
    status(body: CustomizeToolTipStatusInput): Observable<CustomizeToolTipStatusOutput> {
        let url_ = this.baseUrl + "/CustomizeToolTip/Status";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatus(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatus(<any>response_);
                } catch (e) {
                    return <Observable<CustomizeToolTipStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CustomizeToolTipStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatus(response: HttpResponseBase): Observable<CustomizeToolTipStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = CustomizeToolTipStatusOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CustomizeToolTipStatusOutput>(<any>null);
    }

    /**
     * @return All customize tool tip selected successfully.
     */
    selectAll(): Observable<CustomizeToolTipSelectAllOutput> {
        let url_ = this.baseUrl + "/CustomizeToolTip/SelectAll";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAll(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAll(<any>response_);
                } catch (e) {
                    return <Observable<CustomizeToolTipSelectAllOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CustomizeToolTipSelectAllOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAll(response: HttpResponseBase): Observable<CustomizeToolTipSelectAllOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = CustomizeToolTipSelectAllOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CustomizeToolTipSelectAllOutput>(<any>null);
    }

    /**
     * @return Customize tool tip selected successfully.
     */
    selectById(customizeTooltipId: number): Observable<CustomizeToolTipSelectByIdOutput> {
        let url_ = this.baseUrl + "/CustomizeToolTip/SelectById/{customizeTooltipId}";
        if (customizeTooltipId === undefined || customizeTooltipId === null)
            throw new Error("The parameter 'customizeTooltipId' must be defined.");
        url_ = url_.replace("{customizeTooltipId}", encodeURIComponent("" + customizeTooltipId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectById(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectById(<any>response_);
                } catch (e) {
                    return <Observable<CustomizeToolTipSelectByIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CustomizeToolTipSelectByIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectById(response: HttpResponseBase): Observable<CustomizeToolTipSelectByIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = CustomizeToolTipSelectByIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CustomizeToolTipSelectByIdOutput>(<any>null);
    }
}
