import { PathPointDataInput } from './path-point-data-input.model';


export class TourAddInput implements ITourAddInput {
    /** The auto generated id from branch details master */
    branchDetailId!: number;
    /** The trip type should be Automatic or Manual */
    tripType!: string;
    /** The auto generated id from vehicle details master */
    vehicleDetailsId!: number;
    /** The start date 15 character limit */
    startDate!: string;
    /** The start date 15 character limit */
    endDate!: string;
    /** The stoppage duration */
    stoppageDuration!: number;
    /** The start point 5000 character limit */
    startPoint!: string;
    /** The start point 5000 character limit */
    endPoint!: string;
    /** The selected start point lat */
    startPointLat!: number;
    /** The selected start point long */
    startPointLong!: number;
    /** The selected end point lat */
    endPointLat!: number;
    /** The selected end point long */
    endPointLong!: number;
    /** The distance */
    distance!: number;
    /** The duration 10 character limit */
    duration!: string;
    /** The tour name 255 character limit */
    tourName!: string;
    pathPointData!: PathPointDataInput[];

    constructor(data?: ITourAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.branchDetailId = _data["branchDetailId"];
            this.tripType = _data["tripType"];
            this.vehicleDetailsId = _data["vehicleDetailsId"];
            this.startDate = _data["startDate"];
            this.endDate = _data["endDate"];
            this.stoppageDuration = _data["stoppageDuration"];
            this.startPoint = _data["startPoint"];
            this.endPoint = _data["endPoint"];
            this.startPointLat = _data["startPointLat"];
            this.startPointLong = _data["startPointLong"];
            this.endPointLat = _data["endPointLat"];
            this.endPointLong = _data["endPointLong"];
            this.distance = _data["distance"];
            this.duration = _data["duration"];
            this.tourName = _data["tourName"];
            if (Array.isArray(_data["pathPointData"])) {
                this.pathPointData = [] as any;
                for (let item of _data["pathPointData"])
                    this.pathPointData!.push(PathPointDataInput.fromJS(item));
            }
        }
    }

    static fromJS(data: any): TourAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new TourAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["branchDetailId"] = this.branchDetailId;
        data["tripType"] = this.tripType;
        data["vehicleDetailsId"] = this.vehicleDetailsId;
        data["startDate"] = this.startDate;
        data["endDate"] = this.endDate;
        data["stoppageDuration"] = this.stoppageDuration;
        data["startPoint"] = this.startPoint;
        data["endPoint"] = this.endPoint;
        data["startPointLat"] = this.startPointLat;
        data["startPointLong"] = this.startPointLong;
        data["endPointLat"] = this.endPointLat;
        data["endPointLong"] = this.endPointLong;
        data["distance"] = this.distance;
        data["duration"] = this.duration;
        data["tourName"] = this.tourName;
        if (Array.isArray(this.pathPointData)) {
            data["pathPointData"] = [];
            for (let item of this.pathPointData)
                data["pathPointData"].push(item.toJSON());
        }
        return data; 
    }
}

export interface ITourAddInput {
    /** The auto generated id from branch details master */
    branchDetailId: number;
    /** The trip type should be Automatic or Manual */
    tripType: string;
    /** The auto generated id from vehicle details master */
    vehicleDetailsId: number;
    /** The start date 15 character limit */
    startDate: string;
    /** The start date 15 character limit */
    endDate: string;
    /** The stoppage duration */
    stoppageDuration: number;
    /** The start point 5000 character limit */
    startPoint: string;
    /** The start point 5000 character limit */
    endPoint: string;
    /** The selected start point lat */
    startPointLat: number;
    /** The selected start point long */
    startPointLong: number;
    /** The selected end point lat */
    endPointLat: number;
    /** The selected end point long */
    endPointLong: number;
    /** The distance */
    distance: number;
    /** The duration 10 character limit */
    duration: string;
    /** The tour name 255 character limit */
    tourName: string;
    pathPointData: PathPointDataInput[];
}

