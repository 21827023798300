import { ExpenseVehicleDetailsInput } from './expense-vehicle-details-input.model';


export class ExpenseHistoryInput implements IExpenseHistoryInput {
    /** The alert from time with 10 character limit */
    fromDate!: string;
    /** The alert to time with 10 character limit */
    toDate!: string;
    vehicleDetailsData!: ExpenseVehicleDetailsInput[];

    constructor(data?: IExpenseHistoryInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.fromDate = _data["fromDate"];
            this.toDate = _data["toDate"];
            if (Array.isArray(_data["vehicleDetailsData"])) {
                this.vehicleDetailsData = [] as any;
                for (let item of _data["vehicleDetailsData"])
                    this.vehicleDetailsData!.push(ExpenseVehicleDetailsInput.fromJS(item));
            }
        }
    }

    static fromJS(data: any): ExpenseHistoryInput {
        data = typeof data === 'object' ? data : {};
        let result = new ExpenseHistoryInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["fromDate"] = this.fromDate;
        data["toDate"] = this.toDate;
        if (Array.isArray(this.vehicleDetailsData)) {
            data["vehicleDetailsData"] = [];
            for (let item of this.vehicleDetailsData)
                data["vehicleDetailsData"].push(item.toJSON());
        }
        return data; 
    }
}

export interface IExpenseHistoryInput {
    /** The alert from time with 10 character limit */
    fromDate: string;
    /** The alert to time with 10 character limit */
    toDate: string;
    vehicleDetailsData: ExpenseVehicleDetailsInput[];
}

