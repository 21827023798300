

export class VehicleCountByCompanyDetailsIdDataOutput implements IVehicleCountByCompanyDetailsIdDataOutput {
    /** Vehicle count by company details id */
    vehicle_count_by_company!: number;

    constructor(data?: IVehicleCountByCompanyDetailsIdDataOutput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.vehicle_count_by_company = _data["vehicle_count_by_company"];
        }
    }

    static fromJS(data: any): VehicleCountByCompanyDetailsIdDataOutput {
        data = typeof data === 'object' ? data : {};
        let result = new VehicleCountByCompanyDetailsIdDataOutput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["vehicle_count_by_company"] = this.vehicle_count_by_company;
        return data; 
    }
}

export interface IVehicleCountByCompanyDetailsIdDataOutput {
    /** Vehicle count by company details id */
    vehicle_count_by_company: number;
}

