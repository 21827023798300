import { SelectApplicationSubMenuDemoTableOutputDataData } from './select-application-sub-menu-demo-table-output-data-data.model';


export class SelectApplicationSubMenuDemoTableOutputData implements ISelectApplicationSubMenuDemoTableOutputData {
    data!: SelectApplicationSubMenuDemoTableOutputDataData[];
    page!: number;
    noOf!: number;
    total!: number;

    constructor(data?: ISelectApplicationSubMenuDemoTableOutputData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["data"])) {
                this.data = [] as any;
                for (let item of _data["data"])
                    this.data!.push(SelectApplicationSubMenuDemoTableOutputDataData.fromJS(item));
            }
            this.page = _data["page"];
            this.noOf = _data["noOf"];
            this.total = _data["total"];
        }
    }

    static fromJS(data: any): SelectApplicationSubMenuDemoTableOutputData {
        data = typeof data === 'object' ? data : {};
        let result = new SelectApplicationSubMenuDemoTableOutputData();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.data)) {
            data["data"] = [];
            for (let item of this.data)
                data["data"].push(item.toJSON());
        }
        data["page"] = this.page;
        data["noOf"] = this.noOf;
        data["total"] = this.total;
        return data; 
    }
}

export interface ISelectApplicationSubMenuDemoTableOutputData {
    data: SelectApplicationSubMenuDemoTableOutputDataData[];
    page: number;
    noOf: number;
    total: number;
}

