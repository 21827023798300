

export class UserAllocatedUserOutput implements IUserAllocatedUserOutput {
    /** The vehicle name with number */
    vehicle!: string;

    constructor(data?: IUserAllocatedUserOutput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.vehicle = _data["vehicle"];
        }
    }

    static fromJS(data: any): UserAllocatedUserOutput {
        data = typeof data === 'object' ? data : {};
        let result = new UserAllocatedUserOutput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["vehicle"] = this.vehicle;
        return data; 
    }
}

export interface IUserAllocatedUserOutput {
    /** The vehicle name with number */
    vehicle: string;
}

