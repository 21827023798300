

export class MaintenanceTypeAddInput implements IMaintenanceTypeAddInput {
    /** The maintenance type name with 255 character limit */
    maintenanceTypeName!: string;

    constructor(data?: IMaintenanceTypeAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.maintenanceTypeName = _data["maintenanceTypeName"];
        }
    }

    static fromJS(data: any): MaintenanceTypeAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new MaintenanceTypeAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["maintenanceTypeName"] = this.maintenanceTypeName;
        return data; 
    }
}

export interface IMaintenanceTypeAddInput {
    /** The maintenance type name with 255 character limit */
    maintenanceTypeName: string;
}

