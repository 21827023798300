

export class TemperatureCalibrationUpdateOutput implements ITemperatureCalibrationUpdateOutput {
    status!: number;
    message!: string;

    constructor(data?: ITemperatureCalibrationUpdateOutput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.status = _data["status"];
            this.message = _data["message"];
        }
    }

    static fromJS(data: any): TemperatureCalibrationUpdateOutput {
        data = typeof data === 'object' ? data : {};
        let result = new TemperatureCalibrationUpdateOutput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["status"] = this.status;
        data["message"] = this.message;
        return data; 
    }
}

export interface ITemperatureCalibrationUpdateOutput {
    status: number;
    message: string;
}

