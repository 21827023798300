

export class TourTripDataInput implements ITourTripDataInput {
    /** The auto generated id from tour coordinate master */
    tourCoordinatePointId!: number;
    /** The arrival time should be 20 character */
    arrivalTime!: string;
    /** The arrival halt time should be 20 character */
    arrivalHaltTime!: string;

    constructor(data?: ITourTripDataInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.tourCoordinatePointId = _data["tourCoordinatePointId"];
            this.arrivalTime = _data["arrivalTime"];
            this.arrivalHaltTime = _data["arrivalHaltTime"];
        }
    }

    static fromJS(data: any): TourTripDataInput {
        data = typeof data === 'object' ? data : {};
        let result = new TourTripDataInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["tourCoordinatePointId"] = this.tourCoordinatePointId;
        data["arrivalTime"] = this.arrivalTime;
        data["arrivalHaltTime"] = this.arrivalHaltTime;
        return data; 
    }
}

export interface ITourTripDataInput {
    /** The auto generated id from tour coordinate master */
    tourCoordinatePointId: number;
    /** The arrival time should be 20 character */
    arrivalTime: string;
    /** The arrival halt time should be 20 character */
    arrivalHaltTime: string;
}

