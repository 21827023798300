

export class LanguageAddInput implements ILanguageAddInput {
    /** The language name with 255 character limit */
    languageName!: string;

    constructor(data?: ILanguageAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.languageName = _data["languageName"];
        }
    }

    static fromJS(data: any): LanguageAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new LanguageAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["languageName"] = this.languageName;
        return data; 
    }
}

export interface ILanguageAddInput {
    /** The language name with 255 character limit */
    languageName: string;
}

