

export class LoginInput implements ILoginInput {
    /** The user name  of user */
    userName!: string;
    /** The password of user with 100 character limit */
    password!: string;
    /** Login Type must be one of web or mobile. */
    loginType!: string;

    constructor(data?: ILoginInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userName = _data["userName"];
            this.password = _data["password"];
            this.loginType = _data["loginType"];
        }
    }

    static fromJS(data: any): LoginInput {
        data = typeof data === 'object' ? data : {};
        let result = new LoginInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userName"] = this.userName;
        data["password"] = this.password;
        data["loginType"] = this.loginType;
        return data; 
    }
}

export interface ILoginInput {
    /** The user name  of user */
    userName: string;
    /** The password of user with 100 character limit */
    password: string;
    /** Login Type must be one of web or mobile. */
    loginType: string;
}

