

export class ToolTipHeaderAddInput implements IToolTipHeaderAddInput {
    /** The header name with 255 character limit */
    tooltipHeaderName!: string;

    constructor(data?: IToolTipHeaderAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.tooltipHeaderName = _data["tooltipHeaderName"];
        }
    }

    static fromJS(data: any): ToolTipHeaderAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new ToolTipHeaderAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["tooltipHeaderName"] = this.tooltipHeaderName;
        return data; 
    }
}

export interface IToolTipHeaderAddInput {
    /** The header name with 255 character limit */
    tooltipHeaderName: string;
}

