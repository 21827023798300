import { UserAddVehicleGroupIdInput } from './user-add-vehicle-group-id-input.model';
import { UserAddBranchDetailIdInput } from './user-add-branch-detail-id-input.model';
import { UserAddVehicleDetailIdInput } from './user-add-vehicle-detail-id-input.model';
import { UserAddApplicationModuleInput } from './user-add-application-module-input.model';


export class UserUpdateInput implements IUserUpdateInput {
    /** The id of the user master */
    userId!: number;
    /** The id of the user type master */
    userTypeId!: number;
    /** The user type name with 255 character limit */
    userName!: string;
    /** The user mobile number with 13 character limit */
    mobileNo!: string;
    /** The user email address with 320 character limit */
    emailAddress!: string;
    /** The id of the user group master */
    userGroupId!: number;
    /** The id of the company detail master */
    companyDetailId!: number;
    /** Branch type must be one of All or Selected. */
    branchType!: string;
    /** Vehicle type must be one of Vehicle Group or Vehicle. */
    vehicleType!: string;
    /** Vehicle group must be one of All or Selected. */
    vehicleGroup!: string;
    /** Branch type must be one of All or Selected. */
    vehicle!: string;
    /** The id of the language group */
    languageId!: number;
    /** The id of the time zone */
    timeZoneId!: number;
    /** Date format must be one of dd-MM-yyyy or MM-dd-yyyy. */
    dateFormat!: string;
    /** Time format must be one of HH:mm:ss or hh:mm:ss a. */
    timeFormat!: string;
    /** Time format must be one of Pie or Bar. */
    vehicleStatusChart!: string;
    /** 0 For No, 1 for Yes */
    showCoverPageOnDownload!: number;
    /** 0 For No, 1 for Yes */
    smoothLiveTracking!: number;
    /** 0 For No, 1 for Yes */
    immobilization!: number;
    /** Time format must be one of None, All, Web or Mobile. */
    applicationNotification!: string;
    /** 0 For No, 1 for Yes */
    showCompanyLogo!: number;
    vehicleGroupId!: UserAddVehicleGroupIdInput[];
    branchDetailId!: UserAddBranchDetailIdInput[];
    vehicleDetail!: UserAddVehicleDetailIdInput[];
    applicationModule!: UserAddApplicationModuleInput[];

    constructor(data?: IUserUpdateInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.userId = _data["userId"];
            this.userTypeId = _data["userTypeId"];
            this.userName = _data["userName"];
            this.mobileNo = _data["mobileNo"];
            this.emailAddress = _data["emailAddress"];
            this.userGroupId = _data["userGroupId"];
            this.companyDetailId = _data["companyDetailId"];
            this.branchType = _data["branchType"];
            this.vehicleType = _data["vehicleType"];
            this.vehicleGroup = _data["vehicleGroup"];
            this.vehicle = _data["vehicle"];
            this.languageId = _data["languageId"];
            this.timeZoneId = _data["timeZoneId"];
            this.dateFormat = _data["dateFormat"];
            this.timeFormat = _data["timeFormat"];
            this.vehicleStatusChart = _data["vehicleStatusChart"];
            this.showCoverPageOnDownload = _data["showCoverPageOnDownload"];
            this.smoothLiveTracking = _data["smoothLiveTracking"];
            this.immobilization = _data["immobilization"];
            this.applicationNotification = _data["applicationNotification"];
            this.showCompanyLogo = _data["showCompanyLogo"];
            if (Array.isArray(_data["vehicleGroupId"])) {
                this.vehicleGroupId = [] as any;
                for (let item of _data["vehicleGroupId"])
                    this.vehicleGroupId!.push(UserAddVehicleGroupIdInput.fromJS(item));
            }
            if (Array.isArray(_data["branchDetailId"])) {
                this.branchDetailId = [] as any;
                for (let item of _data["branchDetailId"])
                    this.branchDetailId!.push(UserAddBranchDetailIdInput.fromJS(item));
            }
            if (Array.isArray(_data["vehicleDetail"])) {
                this.vehicleDetail = [] as any;
                for (let item of _data["vehicleDetail"])
                    this.vehicleDetail!.push(UserAddVehicleDetailIdInput.fromJS(item));
            }
            if (Array.isArray(_data["applicationModule"])) {
                this.applicationModule = [] as any;
                for (let item of _data["applicationModule"])
                    this.applicationModule!.push(UserAddApplicationModuleInput.fromJS(item));
            }
        }
    }

    static fromJS(data: any): UserUpdateInput {
        data = typeof data === 'object' ? data : {};
        let result = new UserUpdateInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["userId"] = this.userId;
        data["userTypeId"] = this.userTypeId;
        data["userName"] = this.userName;
        data["mobileNo"] = this.mobileNo;
        data["emailAddress"] = this.emailAddress;
        data["userGroupId"] = this.userGroupId;
        data["companyDetailId"] = this.companyDetailId;
        data["branchType"] = this.branchType;
        data["vehicleType"] = this.vehicleType;
        data["vehicleGroup"] = this.vehicleGroup;
        data["vehicle"] = this.vehicle;
        data["languageId"] = this.languageId;
        data["timeZoneId"] = this.timeZoneId;
        data["dateFormat"] = this.dateFormat;
        data["timeFormat"] = this.timeFormat;
        data["vehicleStatusChart"] = this.vehicleStatusChart;
        data["showCoverPageOnDownload"] = this.showCoverPageOnDownload;
        data["smoothLiveTracking"] = this.smoothLiveTracking;
        data["immobilization"] = this.immobilization;
        data["applicationNotification"] = this.applicationNotification;
        data["showCompanyLogo"] = this.showCompanyLogo;
        if (Array.isArray(this.vehicleGroupId)) {
            data["vehicleGroupId"] = [];
            for (let item of this.vehicleGroupId)
                data["vehicleGroupId"].push(item.toJSON());
        }
        if (Array.isArray(this.branchDetailId)) {
            data["branchDetailId"] = [];
            for (let item of this.branchDetailId)
                data["branchDetailId"].push(item.toJSON());
        }
        if (Array.isArray(this.vehicleDetail)) {
            data["vehicleDetail"] = [];
            for (let item of this.vehicleDetail)
                data["vehicleDetail"].push(item.toJSON());
        }
        if (Array.isArray(this.applicationModule)) {
            data["applicationModule"] = [];
            for (let item of this.applicationModule)
                data["applicationModule"].push(item.toJSON());
        }
        return data; 
    }
}

export interface IUserUpdateInput {
    /** The id of the user master */
    userId: number;
    /** The id of the user type master */
    userTypeId: number;
    /** The user type name with 255 character limit */
    userName: string;
    /** The user mobile number with 13 character limit */
    mobileNo: string;
    /** The user email address with 320 character limit */
    emailAddress: string;
    /** The id of the user group master */
    userGroupId: number;
    /** The id of the company detail master */
    companyDetailId: number;
    /** Branch type must be one of All or Selected. */
    branchType: string;
    /** Vehicle type must be one of Vehicle Group or Vehicle. */
    vehicleType: string;
    /** Vehicle group must be one of All or Selected. */
    vehicleGroup: string;
    /** Branch type must be one of All or Selected. */
    vehicle: string;
    /** The id of the language group */
    languageId: number;
    /** The id of the time zone */
    timeZoneId: number;
    /** Date format must be one of dd-MM-yyyy or MM-dd-yyyy. */
    dateFormat: string;
    /** Time format must be one of HH:mm:ss or hh:mm:ss a. */
    timeFormat: string;
    /** Time format must be one of Pie or Bar. */
    vehicleStatusChart: string;
    /** 0 For No, 1 for Yes */
    showCoverPageOnDownload: number;
    /** 0 For No, 1 for Yes */
    smoothLiveTracking: number;
    /** 0 For No, 1 for Yes */
    immobilization: number;
    /** Time format must be one of None, All, Web or Mobile. */
    applicationNotification: string;
    /** 0 For No, 1 for Yes */
    showCompanyLogo: number;
    vehicleGroupId: UserAddVehicleGroupIdInput[];
    branchDetailId: UserAddBranchDetailIdInput[];
    vehicleDetail: UserAddVehicleDetailIdInput[];
    applicationModule: UserAddApplicationModuleInput[];
}

