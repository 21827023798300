import { CompanyBranchDataOutput } from './company-branch-data-output.model';


export class CompanyBranchOutputData implements ICompanyBranchOutputData {
    /** The Reference id of the tbl_company_detail table */
    company_details_id!: number;
    /** The company name with 255 character limit */
    company_name!: string;
    /** The number of remaining vehicle of company */
    remaining_vehicle!: number;
    branch!: CompanyBranchDataOutput[];

    constructor(data?: ICompanyBranchOutputData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.company_details_id = _data["company_details_id"];
            this.company_name = _data["company_name"];
            this.remaining_vehicle = _data["remaining_vehicle"];
            if (Array.isArray(_data["branch"])) {
                this.branch = [] as any;
                for (let item of _data["branch"])
                    this.branch!.push(CompanyBranchDataOutput.fromJS(item));
            }
        }
    }

    static fromJS(data: any): CompanyBranchOutputData {
        data = typeof data === 'object' ? data : {};
        let result = new CompanyBranchOutputData();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["company_details_id"] = this.company_details_id;
        data["company_name"] = this.company_name;
        data["remaining_vehicle"] = this.remaining_vehicle;
        if (Array.isArray(this.branch)) {
            data["branch"] = [];
            for (let item of this.branch)
                data["branch"].push(item.toJSON());
        }
        return data; 
    }
}

export interface ICompanyBranchOutputData {
    /** The Reference id of the tbl_company_detail table */
    company_details_id: number;
    /** The company name with 255 character limit */
    company_name: string;
    /** The number of remaining vehicle of company */
    remaining_vehicle: number;
    branch: CompanyBranchDataOutput[];
}

