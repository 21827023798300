import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { Blob } from '../models/blob.model';
import { SelectByCompanyIdBranchIdAndVehicleTypeIdInput } from '../models/select-by-company-id-branch-id-and-vehicle-type-id-input.model';
import { SelectByCompanyIdBranchIdAndVehicleTypeIdOutput } from '../models/select-by-company-id-branch-id-and-vehicle-type-id-output.model';
import { VehicleCountByCompanyDetailsIdOutput } from '../models/vehicle-count-by-company-details-id-output.model';
import { VehicleDetailsAddOutput } from '../models/vehicle-details-add-output.model';
import { VehicleDetailsDocumentAddOutput } from '../models/vehicle-details-document-add-output.model';
import { VehicleDetailsSelectActiveOutput } from '../models/vehicle-details-select-active-output.model';
import { VehicleDetailsSelectAllOutput } from '../models/vehicle-details-select-all-output.model';
import { VehicleDetailsSelectByBranchDetailsIdOutput } from '../models/vehicle-details-select-by-branch-details-id-output.model';
import { VehicleDetailsSelectByCompanyDetailsIdOutput } from '../models/vehicle-details-select-by-company-details-id-output.model';
import { VehicleDetailsSelectByIdOutput } from '../models/vehicle-details-select-by-id-output.model';
import { VehicleDetailsStatusInput } from '../models/vehicle-details-status-input.model';
import { VehicleDetailsStatusOutput } from '../models/vehicle-details-status-output.model';
import { VehicleDetailsUpdateOutput } from '../models/vehicle-details-update-output.model';
import { VehicleDocumentDetailsSelectActiveOutput } from '../models/vehicle-document-details-select-active-output.model';
import { VehicleDocumentDetailsSelectAllOutput } from '../models/vehicle-document-details-select-all-output.model';
import { VehicleDocumentDetailsSelectByIdOutput } from '../models/vehicle-document-details-select-by-id-output.model';
import { VehicleDocumentDetailsStatusInput } from '../models/vehicle-document-details-status-input.model';
import { VehicleDocumentDetailsStatusOutput } from '../models/vehicle-document-details-status-output.model';
import { VehicleDocumentDetailsUpdateOutput } from '../models/vehicle-document-details-update-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class VehicleDetailsServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3001/api/v1";
    }

    /**
     * @return Vehicle details added successfully.
     */
    add(body: Blob): Observable<VehicleDetailsAddOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/Add";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = body;

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                //"Content-Type": "multipart/form-data",
                "Accept": "*/*"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processAdd(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAdd(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDetailsAddOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDetailsAddOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAdd(response: HttpResponseBase): Observable<VehicleDetailsAddOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDetailsAddOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDetailsAddOutput>(<any>null);
    }

    /**
     * @return Vehicle details updated successfully.
     */
    update(body: Blob): Observable<VehicleDetailsUpdateOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/Update";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = body;

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                //"Content-Type": "multipart/form-data",
                "Accept": "*/*"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processUpdate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdate(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDetailsUpdateOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDetailsUpdateOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdate(response: HttpResponseBase): Observable<VehicleDetailsUpdateOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDetailsUpdateOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDetailsUpdateOutput>(<any>null);
    }

    /**
     * @return Vehicle details status updated successfully.
     */
    status(body: VehicleDetailsStatusInput): Observable<VehicleDetailsStatusOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/Status";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processStatus(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatus(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDetailsStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDetailsStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatus(response: HttpResponseBase): Observable<VehicleDetailsStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDetailsStatusOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDetailsStatusOutput>(<any>null);
    }

    /**
     * @return All vehicle details  selected successfully.
     */
    selectAll(): Observable<VehicleDetailsSelectAllOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/SelectAll";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectAll(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAll(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDetailsSelectAllOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDetailsSelectAllOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAll(response: HttpResponseBase): Observable<VehicleDetailsSelectAllOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDetailsSelectAllOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDetailsSelectAllOutput>(<any>null);
    }

    /**
     * @return Active vehicle details selected successfully.
     */
    selectActive(): Observable<VehicleDetailsSelectActiveOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/SelectActive";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectActive(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActive(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDetailsSelectActiveOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDetailsSelectActiveOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActive(response: HttpResponseBase): Observable<VehicleDetailsSelectActiveOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDetailsSelectActiveOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDetailsSelectActiveOutput>(<any>null);
    }

    /**
     * @return Vehicle details selected successfully.
     */
    selectById(vehicleDetailsId: number): Observable<VehicleDetailsSelectByIdOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/SelectById/{vehicleDetailsId}";
        if (vehicleDetailsId === undefined || vehicleDetailsId === null)
            throw new Error("The parameter 'vehicleDetailsId' must be defined.");
        url_ = url_.replace("{vehicleDetailsId}", encodeURIComponent("" + vehicleDetailsId));
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectById(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectById(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDetailsSelectByIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDetailsSelectByIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectById(response: HttpResponseBase): Observable<VehicleDetailsSelectByIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDetailsSelectByIdOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDetailsSelectByIdOutput>(<any>null);
    }

    /**
     * @return Vehicle details selected successfully.
     */
    selectByCompanyId(companyDetailId: number): Observable<VehicleDetailsSelectByCompanyDetailsIdOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/SelectByCompanyId/{companyDetailId}";
        if (companyDetailId === undefined || companyDetailId === null)
            throw new Error("The parameter 'companyDetailId' must be defined.");
        url_ = url_.replace("{companyDetailId}", encodeURIComponent("" + companyDetailId));
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectByCompanyId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectByCompanyId(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDetailsSelectByCompanyDetailsIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDetailsSelectByCompanyDetailsIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectByCompanyId(response: HttpResponseBase): Observable<VehicleDetailsSelectByCompanyDetailsIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDetailsSelectByCompanyDetailsIdOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDetailsSelectByCompanyDetailsIdOutput>(<any>null);
    }

    /**
     * @return Vehicle details selected successfully.
     */
    selectByBranchId(branchDetailId: number): Observable<VehicleDetailsSelectByBranchDetailsIdOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/SelectByBranchId/{branchDetailId}";
        if (branchDetailId === undefined || branchDetailId === null)
            throw new Error("The parameter 'branchDetailId' must be defined.");
        url_ = url_.replace("{branchDetailId}", encodeURIComponent("" + branchDetailId));
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectByBranchId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectByBranchId(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDetailsSelectByBranchDetailsIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDetailsSelectByBranchDetailsIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectByBranchId(response: HttpResponseBase): Observable<VehicleDetailsSelectByBranchDetailsIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDetailsSelectByBranchDetailsIdOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDetailsSelectByBranchDetailsIdOutput>(<any>null);
    }

    /**
     * @return Vehicle details selected successfully.
     */
    selectByCompanyIdBranchIdAndVehicleTypeId(body: SelectByCompanyIdBranchIdAndVehicleTypeIdInput): Observable<SelectByCompanyIdBranchIdAndVehicleTypeIdOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/SelectByCompanyIdBranchIdAndVehicleTypeId";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectByCompanyIdBranchIdAndVehicleTypeId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectByCompanyIdBranchIdAndVehicleTypeId(<any>response_);
                } catch (e) {
                    return <Observable<SelectByCompanyIdBranchIdAndVehicleTypeIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectByCompanyIdBranchIdAndVehicleTypeIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectByCompanyIdBranchIdAndVehicleTypeId(response: HttpResponseBase): Observable<SelectByCompanyIdBranchIdAndVehicleTypeIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = SelectByCompanyIdBranchIdAndVehicleTypeIdOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectByCompanyIdBranchIdAndVehicleTypeIdOutput>(<any>null);
    }

    /**
     * @return Vehicle details document added successfully.
     */
    addVehicleDocumentDetails(body: Blob): Observable<VehicleDetailsDocumentAddOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/AddVehicleDocumentDetails";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = body;

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                //"Content-Type": "multipart/form-data",
                "Accept": "*/*"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processAddVehicleDocumentDetails(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAddVehicleDocumentDetails(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDetailsDocumentAddOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDetailsDocumentAddOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAddVehicleDocumentDetails(response: HttpResponseBase): Observable<VehicleDetailsDocumentAddOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDetailsDocumentAddOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDetailsDocumentAddOutput>(<any>null);
    }

    /**
     * @return Vehicle details document updated successfully.
     */
    updateVehicleDocumentDetails(body: Blob): Observable<VehicleDocumentDetailsUpdateOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/UpdateVehicleDocumentDetails";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = body;

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                //"Content-Type": "multipart/form-data",
                "Accept": "*/*"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processUpdateVehicleDocumentDetails(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdateVehicleDocumentDetails(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDocumentDetailsUpdateOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDocumentDetailsUpdateOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdateVehicleDocumentDetails(response: HttpResponseBase): Observable<VehicleDocumentDetailsUpdateOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDocumentDetailsUpdateOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDocumentDetailsUpdateOutput>(<any>null);
    }

    /**
     * @return Vehicle details document status updated successfully.
     */
    statusVehicleDocumentDetails(body: VehicleDocumentDetailsStatusInput): Observable<VehicleDocumentDetailsStatusOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/StatusVehicleDocumentDetails";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processStatusVehicleDocumentDetails(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatusVehicleDocumentDetails(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDocumentDetailsStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDocumentDetailsStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatusVehicleDocumentDetails(response: HttpResponseBase): Observable<VehicleDocumentDetailsStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDocumentDetailsStatusOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDocumentDetailsStatusOutput>(<any>null);
    }

    /**
     * @return All vehicle details document selected successfully.
     */
    selectAllVehicleDocumentDetails(vehicleDetailsId: number): Observable<VehicleDocumentDetailsSelectAllOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/SelectAllVehicleDocumentDetails/{vehicleDetailsId}";
        if (vehicleDetailsId === undefined || vehicleDetailsId === null)
            throw new Error("The parameter 'vehicleDetailsId' must be defined.");
        url_ = url_.replace("{vehicleDetailsId}", encodeURIComponent("" + vehicleDetailsId));
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectAllVehicleDocumentDetails(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAllVehicleDocumentDetails(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDocumentDetailsSelectAllOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDocumentDetailsSelectAllOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAllVehicleDocumentDetails(response: HttpResponseBase): Observable<VehicleDocumentDetailsSelectAllOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDocumentDetailsSelectAllOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDocumentDetailsSelectAllOutput>(<any>null);
    }

    /**
     * @return Active vehicle details document selected successfully.
     */
    selectActiveVehicleDocumentDetails(vehicleDetailsId: number): Observable<VehicleDocumentDetailsSelectActiveOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/SelectActiveVehicleDocumentDetails/{vehicleDetailsId}";
        if (vehicleDetailsId === undefined || vehicleDetailsId === null)
            throw new Error("The parameter 'vehicleDetailsId' must be defined.");
        url_ = url_.replace("{vehicleDetailsId}", encodeURIComponent("" + vehicleDetailsId));
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectActiveVehicleDocumentDetails(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActiveVehicleDocumentDetails(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDocumentDetailsSelectActiveOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDocumentDetailsSelectActiveOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActiveVehicleDocumentDetails(response: HttpResponseBase): Observable<VehicleDocumentDetailsSelectActiveOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDocumentDetailsSelectActiveOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDocumentDetailsSelectActiveOutput>(<any>null);
    }

    /**
     * @return Vehicle details document selected successfully.
     */
    selectByIdVehicleDocumentDetails(vehicleDetailsDocumentId: number): Observable<VehicleDocumentDetailsSelectByIdOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/SelectByIdVehicleDocumentDetails/{vehicleDetailsDocumentId}";
        if (vehicleDetailsDocumentId === undefined || vehicleDetailsDocumentId === null)
            throw new Error("The parameter 'vehicleDetailsDocumentId' must be defined.");
        url_ = url_.replace("{vehicleDetailsDocumentId}", encodeURIComponent("" + vehicleDetailsDocumentId));
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectByIdVehicleDocumentDetails(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectByIdVehicleDocumentDetails(<any>response_);
                } catch (e) {
                    return <Observable<VehicleDocumentDetailsSelectByIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleDocumentDetailsSelectByIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectByIdVehicleDocumentDetails(response: HttpResponseBase): Observable<VehicleDocumentDetailsSelectByIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleDocumentDetailsSelectByIdOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleDocumentDetailsSelectByIdOutput>(<any>null);
    }

    /**
     * @return vehicle count by company selected successfully.
     */
    getVehicleCountByCompanyId(companyDetailId: number): Observable<VehicleCountByCompanyDetailsIdOutput> {
        let url_ = this.baseUrl + "/VehicleDetails/GetVehicleCountByCompanyId/{companyDetailId}";
        if (companyDetailId === undefined || companyDetailId === null)
            throw new Error("The parameter 'companyDetailId' must be defined.");
        url_ = url_.replace("{companyDetailId}", encodeURIComponent("" + companyDetailId));
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processGetVehicleCountByCompanyId(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processGetVehicleCountByCompanyId(<any>response_);
                } catch (e) {
                    return <Observable<VehicleCountByCompanyDetailsIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<VehicleCountByCompanyDetailsIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processGetVehicleCountByCompanyId(response: HttpResponseBase): Observable<VehicleCountByCompanyDetailsIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = VehicleCountByCompanyDetailsIdOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<VehicleCountByCompanyDetailsIdOutput>(<any>null);
    }
}
