import { SmsAllocationCompanyDetailsAddInput } from './sms-allocation-company-details-add-input.model';


export class SmsAllocationAddInput implements ISmsAllocationAddInput {
    /** The sms allocation name with 255 character limit */
    smsAllocationName!: string;
    /** The compony details type must be one of "All" or "Select" */
    companyDetailType!: string;
    /** The activation date with 10 character limit */
    activationDate!: string;
    /** The expiry date with 10 character limit */
    expiryDate!: string;
    /** The available sms */
    availableSms!: number;
    /** Max sms allocation */
    maxSms!: number;
    /**  Monthly max sms allocation */
    monthlyMaxSms!: number;
    /**  daily max sms allocation */
    dailyMaxSms!: number;
    smsAllocationCompanyDetails!: SmsAllocationCompanyDetailsAddInput[];

    constructor(data?: ISmsAllocationAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.smsAllocationName = _data["smsAllocationName"];
            this.companyDetailType = _data["companyDetailType"];
            this.activationDate = _data["activationDate"];
            this.expiryDate = _data["expiryDate"];
            this.availableSms = _data["availableSms"];
            this.maxSms = _data["maxSms"];
            this.monthlyMaxSms = _data["monthlyMaxSms"];
            this.dailyMaxSms = _data["dailyMaxSms"];
            if (Array.isArray(_data["smsAllocationCompanyDetails"])) {
                this.smsAllocationCompanyDetails = [] as any;
                for (let item of _data["smsAllocationCompanyDetails"])
                    this.smsAllocationCompanyDetails!.push(SmsAllocationCompanyDetailsAddInput.fromJS(item));
            }
        }
    }

    static fromJS(data: any): SmsAllocationAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new SmsAllocationAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["smsAllocationName"] = this.smsAllocationName;
        data["companyDetailType"] = this.companyDetailType;
        data["activationDate"] = this.activationDate;
        data["expiryDate"] = this.expiryDate;
        data["availableSms"] = this.availableSms;
        data["maxSms"] = this.maxSms;
        data["monthlyMaxSms"] = this.monthlyMaxSms;
        data["dailyMaxSms"] = this.dailyMaxSms;
        if (Array.isArray(this.smsAllocationCompanyDetails)) {
            data["smsAllocationCompanyDetails"] = [];
            for (let item of this.smsAllocationCompanyDetails)
                data["smsAllocationCompanyDetails"].push(item.toJSON());
        }
        return data; 
    }
}

export interface ISmsAllocationAddInput {
    /** The sms allocation name with 255 character limit */
    smsAllocationName: string;
    /** The compony details type must be one of "All" or "Select" */
    companyDetailType: string;
    /** The activation date with 10 character limit */
    activationDate: string;
    /** The expiry date with 10 character limit */
    expiryDate: string;
    /** The available sms */
    availableSms: number;
    /** Max sms allocation */
    maxSms: number;
    /**  Monthly max sms allocation */
    monthlyMaxSms: number;
    /**  daily max sms allocation */
    dailyMaxSms: number;
    smsAllocationCompanyDetails: SmsAllocationCompanyDetailsAddInput[];
}

