import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { Blob } from '../models/blob.model';
import { CompanyDetailAddMapInput } from '../models/company-detail-add-map-input.model';
import { CompanyDetailAddMapOutput } from '../models/company-detail-add-map-output.model';
import { CompanyDetailAddOutput } from '../models/company-detail-add-output.model';
import { CompanyDetailSelectActiveMapOutput } from '../models/company-detail-select-active-map-output.model';
import { CompanyDetailSelectActiveOutput } from '../models/company-detail-select-active-output.model';
import { CompanyDetailSelectAllMapOutput } from '../models/company-detail-select-all-map-output.model';
import { CompanyDetailSelectAllOutput } from '../models/company-detail-select-all-output.model';
import { CompanyDetailSelectByIdOutput } from '../models/company-detail-select-by-id-output.model';
import { CompanyDetailSelectMapByIdOutput } from '../models/company-detail-select-map-by-id-output.model';
import { CompanyDetailStatusInput } from '../models/company-detail-status-input.model';
import { CompanyDetailStatusMapInput } from '../models/company-detail-status-map-input.model';
import { CompanyDetailStatusMapOutput } from '../models/company-detail-status-map-output.model';
import { CompanyDetailStatusOutput } from '../models/company-detail-status-output.model';
import { CompanyDetailUpdateMapInput } from '../models/company-detail-update-map-input.model';
import { CompanyDetailUpdateMapOutput } from '../models/company-detail-update-map-output.model';
import { CompanyDetailUpdateOutput } from '../models/company-detail-update-output.model';
import { SelectActiveWithVehicleCounterOutput } from '../models/select-active-with-vehicle-counter-output.model';
import { SelectForDropDownOutput } from '../models/select-for-drop-down-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class CompanyDetailServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3001/api/v1";
    }

    /**
     * @return Company added successfully.
     */
    addCompany(body: Blob): Observable<CompanyDetailAddOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/AddCompany";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = body;

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                //"Content-Type": "multipart/form-data",
                "Accept": "*/*"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processAddCompany(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAddCompany(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailAddOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailAddOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAddCompany(response: HttpResponseBase): Observable<CompanyDetailAddOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailAddOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailAddOutput>(<any>null);
    }

    /**
     * @return Company updated successfully.
     */
    updateCompany(body: Blob): Observable<CompanyDetailUpdateOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/UpdateCompany";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = body;

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                //"Content-Type": "multipart/form-data",
                "Accept": "*/*"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processUpdateCompany(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdateCompany(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailUpdateOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailUpdateOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdateCompany(response: HttpResponseBase): Observable<CompanyDetailUpdateOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailUpdateOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailUpdateOutput>(<any>null);
    }

    /**
     * @return Company status updated successfully.
     */
    statusCompany(body: CompanyDetailStatusInput): Observable<CompanyDetailStatusOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/StatusCompany";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processStatusCompany(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatusCompany(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatusCompany(response: HttpResponseBase): Observable<CompanyDetailStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailStatusOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailStatusOutput>(<any>null);
    }

    /**
     * @return All company selected successfully.
     */
    selectAllCompany(): Observable<CompanyDetailSelectAllOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/SelectAllCompany";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectAllCompany(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAllCompany(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailSelectAllOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailSelectAllOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAllCompany(response: HttpResponseBase): Observable<CompanyDetailSelectAllOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailSelectAllOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailSelectAllOutput>(<any>null);
    }

    /**
     * @return Active company selected successfully.
     */
    selectActiveCompany(): Observable<CompanyDetailSelectActiveOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/SelectActiveCompany";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectActiveCompany(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActiveCompany(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailSelectActiveOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailSelectActiveOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActiveCompany(response: HttpResponseBase): Observable<CompanyDetailSelectActiveOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailSelectActiveOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailSelectActiveOutput>(<any>null);
    }

    /**
     * @return Active company selected successfully.
     */
    selectActiveWithVehicleCounter(): Observable<SelectActiveWithVehicleCounterOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/SelectActiveWithVehicleCounter";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectActiveWithVehicleCounter(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActiveWithVehicleCounter(<any>response_);
                } catch (e) {
                    return <Observable<SelectActiveWithVehicleCounterOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectActiveWithVehicleCounterOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActiveWithVehicleCounter(response: HttpResponseBase): Observable<SelectActiveWithVehicleCounterOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = SelectActiveWithVehicleCounterOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectActiveWithVehicleCounterOutput>(<any>null);
    }

    /**
     * @return Active company selected successfully.
     */
    selectForDropDown(): Observable<SelectForDropDownOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/SelectForDropDown";
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectForDropDown(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectForDropDown(<any>response_);
                } catch (e) {
                    return <Observable<SelectForDropDownOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<SelectForDropDownOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectForDropDown(response: HttpResponseBase): Observable<SelectForDropDownOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = SelectForDropDownOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<SelectForDropDownOutput>(<any>null);
    }

    /**
     * @return Company selected successfully.
     */
    selectCompanyById(companyDetailId: number): Observable<CompanyDetailSelectByIdOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/SelectCompanyById/{companyDetailId}";
        if (companyDetailId === undefined || companyDetailId === null)
            throw new Error("The parameter 'companyDetailId' must be defined.");
        url_ = url_.replace("{companyDetailId}", encodeURIComponent("" + companyDetailId));
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectCompanyById(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectCompanyById(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailSelectByIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailSelectByIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectCompanyById(response: HttpResponseBase): Observable<CompanyDetailSelectByIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailSelectByIdOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailSelectByIdOutput>(<any>null);
    }

    /**
     * @return Company map added successfully.
     */
    addMap(body: CompanyDetailAddMapInput): Observable<CompanyDetailAddMapOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/AddMap";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processAddMap(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAddMap(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailAddMapOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailAddMapOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAddMap(response: HttpResponseBase): Observable<CompanyDetailAddMapOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailAddMapOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailAddMapOutput>(<any>null);
    }

    /**
     * @return Company map updated successfully.
     */
    updateMap(body: CompanyDetailUpdateMapInput): Observable<CompanyDetailUpdateMapOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/UpdateMap";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processUpdateMap(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdateMap(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailUpdateMapOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailUpdateMapOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdateMap(response: HttpResponseBase): Observable<CompanyDetailUpdateMapOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailUpdateMapOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailUpdateMapOutput>(<any>null);
    }

    /**
     * @return Company map status updated successfully.
     */
    statusMap(body: CompanyDetailStatusMapInput): Observable<CompanyDetailStatusMapOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/StatusMap";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_: any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processStatusMap(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatusMap(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailStatusMapOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailStatusMapOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatusMap(response: HttpResponseBase): Observable<CompanyDetailStatusMapOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailStatusMapOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailStatusMapOutput>(<any>null);
    }

    /**
     * @return All company map selected successfully.
     */
    selectAllMap(companyDetailId: number): Observable<CompanyDetailSelectAllMapOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/SelectAllMap/{companyDetailId}";
        if (companyDetailId === undefined || companyDetailId === null)
            throw new Error("The parameter 'companyDetailId' must be defined.");
        url_ = url_.replace("{companyDetailId}", encodeURIComponent("" + companyDetailId));
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectAllMap(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAllMap(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailSelectAllMapOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailSelectAllMapOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAllMap(response: HttpResponseBase): Observable<CompanyDetailSelectAllMapOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailSelectAllMapOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailSelectAllMapOutput>(<any>null);
    }

    /**
     * @return Active company map selected successfully.
     */
    selectActiveMap(companyDetailId: number): Observable<CompanyDetailSelectActiveMapOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/SelectActiveMap/{companyDetailId}";
        if (companyDetailId === undefined || companyDetailId === null)
            throw new Error("The parameter 'companyDetailId' must be defined.");
        url_ = url_.replace("{companyDetailId}", encodeURIComponent("" + companyDetailId));
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectActiveMap(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActiveMap(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailSelectActiveMapOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailSelectActiveMapOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActiveMap(response: HttpResponseBase): Observable<CompanyDetailSelectActiveMapOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailSelectActiveMapOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailSelectActiveMapOutput>(<any>null);
    }

    /**
     * @return Company map selected successfully.
     */
    selectMapById(companyDetailsMapId: number): Observable<CompanyDetailSelectMapByIdOutput> {
        let url_ = this.baseUrl + "/CompanyDetail/SelectMapById/{companyDetailsMapId}";
        if (companyDetailsMapId === undefined || companyDetailsMapId === null)
            throw new Error("The parameter 'companyDetailsMapId' must be defined.");
        url_ = url_.replace("{companyDetailsMapId}", encodeURIComponent("" + companyDetailsMapId));
        url_ = url_.replace(/[?&]$/, "");

        let options_: any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            return this.processSelectMapById(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectMapById(<any>response_);
                } catch (e) {
                    return <Observable<CompanyDetailSelectMapByIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<CompanyDetailSelectMapByIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectMapById(response: HttpResponseBase): Observable<CompanyDetailSelectMapByIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
                (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } }
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                let result200: any = null;
                let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompanyDetailSelectMapByIdOutput.fromJS(resultData200);
                return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
                return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<CompanyDetailSelectMapByIdOutput>(<any>null);
    }
}
