

export class VehicleTypeAddInput implements IVehicleTypeAddInput {
    /** The vehicle type name with 255 character limit */
    vehicleTypeName!: string;

    constructor(data?: IVehicleTypeAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.vehicleTypeName = _data["vehicleTypeName"];
        }
    }

    static fromJS(data: any): VehicleTypeAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new VehicleTypeAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["vehicleTypeName"] = this.vehicleTypeName;
        return data; 
    }
}

export interface IVehicleTypeAddInput {
    /** The vehicle type name with 255 character limit */
    vehicleTypeName: string;
}

