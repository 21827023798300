

export class CompanyDetailOutputMapData implements ICompanyDetailOutputMapData {
    /** The Reference id of the tbl_company_details_map table */
    company_details_map_id!: number;
    /** The Reference id of the tbl_map table */
    map_id!: number;
    /** The web map key with 2048 character limit */
    web_map_key!: string;
    /** The map project id with 2048 character limit */
    map_project_id!: string;
    /** The address from map provider with 2048 character limit */
    address_from_map_provider!: string;
    /** The address from map provider flag must be one of 0 or 1 for available */
    address_from_map_provider_flag!: number;
    /** The speed limit api must be one of 0 or 1 for available */
    speed_limit_api!: number;
    /** The speed limit api must be one of default 1 else 0 */
    default!: number;
    /** The speed limit api must be one of 0 for deactive, 1 for active */
    status!: number;
    /** The map name with 255 character limit */
    map_name!: string;

    constructor(data?: ICompanyDetailOutputMapData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.company_details_map_id = _data["company_details_map_id"];
            this.map_id = _data["map_id"];
            this.web_map_key = _data["web_map_key"];
            this.map_project_id = _data["map_project_id"];
            this.address_from_map_provider = _data["address_from_map_provider"];
            this.address_from_map_provider_flag = _data["address_from_map_provider_flag"];
            this.speed_limit_api = _data["speed_limit_api"];
            this.default = _data["default"];
            this.status = _data["status"];
            this.map_name = _data["map_name"];
        }
    }

    static fromJS(data: any): CompanyDetailOutputMapData {
        data = typeof data === 'object' ? data : {};
        let result = new CompanyDetailOutputMapData();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["company_details_map_id"] = this.company_details_map_id;
        data["map_id"] = this.map_id;
        data["web_map_key"] = this.web_map_key;
        data["map_project_id"] = this.map_project_id;
        data["address_from_map_provider"] = this.address_from_map_provider;
        data["address_from_map_provider_flag"] = this.address_from_map_provider_flag;
        data["speed_limit_api"] = this.speed_limit_api;
        data["default"] = this.default;
        data["status"] = this.status;
        data["map_name"] = this.map_name;
        return data; 
    }
}

export interface ICompanyDetailOutputMapData {
    /** The Reference id of the tbl_company_details_map table */
    company_details_map_id: number;
    /** The Reference id of the tbl_map table */
    map_id: number;
    /** The web map key with 2048 character limit */
    web_map_key: string;
    /** The map project id with 2048 character limit */
    map_project_id: string;
    /** The address from map provider with 2048 character limit */
    address_from_map_provider: string;
    /** The address from map provider flag must be one of 0 or 1 for available */
    address_from_map_provider_flag: number;
    /** The speed limit api must be one of 0 or 1 for available */
    speed_limit_api: number;
    /** The speed limit api must be one of default 1 else 0 */
    default: number;
    /** The speed limit api must be one of 0 for deactive, 1 for active */
    status: number;
    /** The map name with 255 character limit */
    map_name: string;
}

