import { UserSelectByIdOutputDataBranchData } from './user-select-by-id-output-data-branch-data.model';
import { UserSelectByIdOutputDataVehicleGroupData } from './user-select-by-id-output-data-vehicle-group-data.model';
import { UserSelectByIdOutputDataVehicleData } from './user-select-by-id-output-data-vehicle-data.model';
import { UserSelectByIdOutputDataApplicationModuleData } from './user-select-by-id-output-data-application-module-data.model';


export class UserSelectByIdOutputData implements IUserSelectByIdOutputData {
    /** The user type name with 255 character limit */
    user_name!: string;
    /** The user mobile number with 13 character limit */
    mobile_no!: string;
    /** The user email address with 320 character limit */
    email_address!: string;
    /** The security pin with 6 character limit */
    security_pin!: string;
    /** The id of the user group master */
    user_group_id!: number;
    /** Branch type must be one of All or Selected. */
    branch_type!: string;
    branch_detail_id!: UserSelectByIdOutputDataBranchData[];
    /** Vehicle type must be one of Vehicle Group or Vehicle. */
    vehicle_type!: string;
    /** Vehicle group must be one of All or Selected. */
    vehicle_group!: string;
    /** The id of the vehicle group */
    vehicle_group_id!: number;
    /** Branch type must be one of All or Selected. */
    vehicle!: string;
    /** The id of the language group */
    language_id!: number;
    /** The id of the time zone */
    time_zone_id!: number;
    /** Date format must be one of dd-MM-yyyy or MM-dd-yyyy. */
    date_format!: string;
    /** Time format must be one of HH:mm:ss or hh:mm:ss a. */
    time_format!: string;
    /** Time format must be one of Pie or Bar. */
    vehicle_status_chart!: string;
    /** 0 For No, 1 for Yes */
    show_cover_page_on_download!: number;
    /** 0 For No, 1 for Yes */
    smooth_live_tracking!: number;
    /** 0 For No, 1 for Yes */
    immobilization!: number;
    /** Time format must be one of None, All, Web or Mobile. */
    application_notification!: string;
    /** 0 For No, 1 for Yes */
    show_company_logo!: number;
    vehicle_group_list!: UserSelectByIdOutputDataVehicleGroupData[];
    vehicle_list!: UserSelectByIdOutputDataVehicleData[];
    application_module!: UserSelectByIdOutputDataApplicationModuleData[];

    constructor(data?: IUserSelectByIdOutputData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.user_name = _data["user_name"];
            this.mobile_no = _data["mobile_no"];
            this.email_address = _data["email_address"];
            this.security_pin = _data["security_pin"];
            this.user_group_id = _data["user_group_id"];
            this.branch_type = _data["branch_type"];
            if (Array.isArray(_data["branch_detail_id"])) {
                this.branch_detail_id = [] as any;
                for (let item of _data["branch_detail_id"])
                    this.branch_detail_id!.push(UserSelectByIdOutputDataBranchData.fromJS(item));
            }
            this.vehicle_type = _data["vehicle_type"];
            this.vehicle_group = _data["vehicle_group"];
            this.vehicle_group_id = _data["vehicle_group_id"];
            this.vehicle = _data["vehicle"];
            this.language_id = _data["language_id"];
            this.time_zone_id = _data["time_zone_id"];
            this.date_format = _data["date_format"];
            this.time_format = _data["time_format"];
            this.vehicle_status_chart = _data["vehicle_status_chart"];
            this.show_cover_page_on_download = _data["show_cover_page_on_download"];
            this.smooth_live_tracking = _data["smooth_live_tracking"];
            this.immobilization = _data["immobilization"];
            this.application_notification = _data["application_notification"];
            this.show_company_logo = _data["show_company_logo"];
            if (Array.isArray(_data["vehicle_group_list"])) {
                this.vehicle_group_list = [] as any;
                for (let item of _data["vehicle_group_list"])
                    this.vehicle_group_list!.push(UserSelectByIdOutputDataVehicleGroupData.fromJS(item));
            }
            if (Array.isArray(_data["vehicle_list"])) {
                this.vehicle_list = [] as any;
                for (let item of _data["vehicle_list"])
                    this.vehicle_list!.push(UserSelectByIdOutputDataVehicleData.fromJS(item));
            }
            if (Array.isArray(_data["application_module"])) {
                this.application_module = [] as any;
                for (let item of _data["application_module"])
                    this.application_module!.push(UserSelectByIdOutputDataApplicationModuleData.fromJS(item));
            }
        }
    }

    static fromJS(data: any): UserSelectByIdOutputData {
        data = typeof data === 'object' ? data : {};
        let result = new UserSelectByIdOutputData();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["user_name"] = this.user_name;
        data["mobile_no"] = this.mobile_no;
        data["email_address"] = this.email_address;
        data["security_pin"] = this.security_pin;
        data["user_group_id"] = this.user_group_id;
        data["branch_type"] = this.branch_type;
        if (Array.isArray(this.branch_detail_id)) {
            data["branch_detail_id"] = [];
            for (let item of this.branch_detail_id)
                data["branch_detail_id"].push(item.toJSON());
        }
        data["vehicle_type"] = this.vehicle_type;
        data["vehicle_group"] = this.vehicle_group;
        data["vehicle_group_id"] = this.vehicle_group_id;
        data["vehicle"] = this.vehicle;
        data["language_id"] = this.language_id;
        data["time_zone_id"] = this.time_zone_id;
        data["date_format"] = this.date_format;
        data["time_format"] = this.time_format;
        data["vehicle_status_chart"] = this.vehicle_status_chart;
        data["show_cover_page_on_download"] = this.show_cover_page_on_download;
        data["smooth_live_tracking"] = this.smooth_live_tracking;
        data["immobilization"] = this.immobilization;
        data["application_notification"] = this.application_notification;
        data["show_company_logo"] = this.show_company_logo;
        if (Array.isArray(this.vehicle_group_list)) {
            data["vehicle_group_list"] = [];
            for (let item of this.vehicle_group_list)
                data["vehicle_group_list"].push(item.toJSON());
        }
        if (Array.isArray(this.vehicle_list)) {
            data["vehicle_list"] = [];
            for (let item of this.vehicle_list)
                data["vehicle_list"].push(item.toJSON());
        }
        if (Array.isArray(this.application_module)) {
            data["application_module"] = [];
            for (let item of this.application_module)
                data["application_module"].push(item.toJSON());
        }
        return data; 
    }
}

export interface IUserSelectByIdOutputData {
    /** The user type name with 255 character limit */
    user_name: string;
    /** The user mobile number with 13 character limit */
    mobile_no: string;
    /** The user email address with 320 character limit */
    email_address: string;
    /** The security pin with 6 character limit */
    security_pin: string;
    /** The id of the user group master */
    user_group_id: number;
    /** Branch type must be one of All or Selected. */
    branch_type: string;
    branch_detail_id: UserSelectByIdOutputDataBranchData[];
    /** Vehicle type must be one of Vehicle Group or Vehicle. */
    vehicle_type: string;
    /** Vehicle group must be one of All or Selected. */
    vehicle_group: string;
    /** The id of the vehicle group */
    vehicle_group_id: number;
    /** Branch type must be one of All or Selected. */
    vehicle: string;
    /** The id of the language group */
    language_id: number;
    /** The id of the time zone */
    time_zone_id: number;
    /** Date format must be one of dd-MM-yyyy or MM-dd-yyyy. */
    date_format: string;
    /** Time format must be one of HH:mm:ss or hh:mm:ss a. */
    time_format: string;
    /** Time format must be one of Pie or Bar. */
    vehicle_status_chart: string;
    /** 0 For No, 1 for Yes */
    show_cover_page_on_download: number;
    /** 0 For No, 1 for Yes */
    smooth_live_tracking: number;
    /** 0 For No, 1 for Yes */
    immobilization: number;
    /** Time format must be one of None, All, Web or Mobile. */
    application_notification: string;
    /** 0 For No, 1 for Yes */
    show_company_logo: number;
    vehicle_group_list: UserSelectByIdOutputDataVehicleGroupData[];
    vehicle_list: UserSelectByIdOutputDataVehicleData[];
    application_module: UserSelectByIdOutputDataApplicationModuleData[];
}

