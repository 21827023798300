import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { ToolTipOptionAddInput } from '../models/tool-tip-option-add-input.model';
import { ToolTipOptionAddOutput } from '../models/tool-tip-option-add-output.model';
import { ToolTipOptionSelectActiveOutput } from '../models/tool-tip-option-select-active-output.model';
import { ToolTipOptionSelectAllOutput } from '../models/tool-tip-option-select-all-output.model';
import { ToolTipOptionStatusInput } from '../models/tool-tip-option-status-input.model';
import { ToolTipOptionStatusOutput } from '../models/tool-tip-option-status-output.model';
import { ToolTipOptionUpdateInput } from '../models/tool-tip-option-update-input.model';
import { ToolTipOptionUpdateOutput } from '../models/tool-tip-option-update-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class ToolTipOptionServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3001/api/v1";
    }

    /**
     * @return Tool tip option added successfully.
     */
    add(body: ToolTipOptionAddInput): Observable<ToolTipOptionAddOutput> {
        let url_ = this.baseUrl + "/ToolTipOption/Add";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAdd(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAdd(<any>response_);
                } catch (e) {
                    return <Observable<ToolTipOptionAddOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ToolTipOptionAddOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAdd(response: HttpResponseBase): Observable<ToolTipOptionAddOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ToolTipOptionAddOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ToolTipOptionAddOutput>(<any>null);
    }

    /**
     * @return Tool tip option updated successfully.
     */
    update(body: ToolTipOptionUpdateInput): Observable<ToolTipOptionUpdateOutput> {
        let url_ = this.baseUrl + "/ToolTipOption/Update";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUpdate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdate(<any>response_);
                } catch (e) {
                    return <Observable<ToolTipOptionUpdateOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ToolTipOptionUpdateOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdate(response: HttpResponseBase): Observable<ToolTipOptionUpdateOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ToolTipOptionUpdateOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ToolTipOptionUpdateOutput>(<any>null);
    }

    /**
     * @return Tool tip option status updated successfully.
     */
    status(body: ToolTipOptionStatusInput): Observable<ToolTipOptionStatusOutput> {
        let url_ = this.baseUrl + "/ToolTipOption/Status";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatus(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatus(<any>response_);
                } catch (e) {
                    return <Observable<ToolTipOptionStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ToolTipOptionStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatus(response: HttpResponseBase): Observable<ToolTipOptionStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ToolTipOptionStatusOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ToolTipOptionStatusOutput>(<any>null);
    }

    /**
     * @return Tool tip option selected successfully.
     */
    selectAll(tooltipHeaderId: number): Observable<ToolTipOptionSelectAllOutput> {
        let url_ = this.baseUrl + "/ToolTipOption/SelectAll/{tooltipHeaderId}";
        if (tooltipHeaderId === undefined || tooltipHeaderId === null)
            throw new Error("The parameter 'tooltipHeaderId' must be defined.");
        url_ = url_.replace("{tooltipHeaderId}", encodeURIComponent("" + tooltipHeaderId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAll(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAll(<any>response_);
                } catch (e) {
                    return <Observable<ToolTipOptionSelectAllOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ToolTipOptionSelectAllOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAll(response: HttpResponseBase): Observable<ToolTipOptionSelectAllOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ToolTipOptionSelectAllOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ToolTipOptionSelectAllOutput>(<any>null);
    }

    /**
     * @return Tool tip option selected successfully.
     */
    selectActive(tooltipHeaderId: number): Observable<ToolTipOptionSelectActiveOutput> {
        let url_ = this.baseUrl + "/ToolTipOption/SelectActive/{tooltipHeaderId}";
        if (tooltipHeaderId === undefined || tooltipHeaderId === null)
            throw new Error("The parameter 'tooltipHeaderId' must be defined.");
        url_ = url_.replace("{tooltipHeaderId}", encodeURIComponent("" + tooltipHeaderId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectActive(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectActive(<any>response_);
                } catch (e) {
                    return <Observable<ToolTipOptionSelectActiveOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ToolTipOptionSelectActiveOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectActive(response: HttpResponseBase): Observable<ToolTipOptionSelectActiveOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ToolTipOptionSelectActiveOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ToolTipOptionSelectActiveOutput>(<any>null);
    }
}
