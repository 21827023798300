

export class DeviceModelPortAllocationAddInput implements IDeviceModelPortAllocationAddInput {
    /** The deviceModelPortAllocation name with 255 character limit */
    deviceModelPortAllocationName!: string;

    constructor(data?: IDeviceModelPortAllocationAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.deviceModelPortAllocationName = _data["deviceModelPortAllocationName"];
        }
    }

    static fromJS(data: any): DeviceModelPortAllocationAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new DeviceModelPortAllocationAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["deviceModelPortAllocationName"] = this.deviceModelPortAllocationName;
        return data; 
    }
}

export interface IDeviceModelPortAllocationAddInput {
    /** The deviceModelPortAllocation name with 255 character limit */
    deviceModelPortAllocationName: string;
}

