

export class DeviceBrandAddInput implements IDeviceBrandAddInput {
    /** The device brand name with 255 character limit */
    deviceBrandName!: string;

    constructor(data?: IDeviceBrandAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.deviceBrandName = _data["deviceBrandName"];
        }
    }

    static fromJS(data: any): DeviceBrandAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new DeviceBrandAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["deviceBrandName"] = this.deviceBrandName;
        return data; 
    }
}

export interface IDeviceBrandAddInput {
    /** The device brand name with 255 character limit */
    deviceBrandName: string;
}

