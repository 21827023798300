

export class TourAllocationStatusInput implements ITourAllocationStatusInput {
    /** The auto generated id from tour allocation master */
    tourAllocationId!: number;
    /** The updated status */
    status!: number;

    constructor(data?: ITourAllocationStatusInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.tourAllocationId = _data["tourAllocationId"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): TourAllocationStatusInput {
        data = typeof data === 'object' ? data : {};
        let result = new TourAllocationStatusInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["tourAllocationId"] = this.tourAllocationId;
        data["status"] = this.status;
        return data; 
    }
}

export interface ITourAllocationStatusInput {
    /** The auto generated id from tour allocation master */
    tourAllocationId: number;
    /** The updated status */
    status: number;
}

