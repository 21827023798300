

export class FuelStationAddInput implements IFuelStationAddInput {
    /** The fuel station name with 255 character limit */
    fuelStationName!: string;

    constructor(data?: IFuelStationAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.fuelStationName = _data["fuelStationName"];
        }
    }

    static fromJS(data: any): FuelStationAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new FuelStationAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["fuelStationName"] = this.fuelStationName;
        return data; 
    }
}

export interface IFuelStationAddInput {
    /** The fuel station name with 255 character limit */
    fuelStationName: string;
}

