

export class GeofenceAddInput implements IGeofenceAddInput {
    /** The company type should be All or Selected */
    companyType!: string;
    /** The branch type should be All or Selected */
    branchType!: string;
    /** The place name with 255 character limit */
    name!: string;
    /** Selected geofence data */
    geofenceData!: string;
    /** Selected shapeData data */
    shapeData!: string;
    /** Selected category for geofence */
    category!: string;
    /** The description with 5000 character limit */
    description!: string;
    /** The consider for trip */
    considerForTrip!: number;
    /** Fence type */
    fenceType!: string;
    /** The tolerance of selected place */
    tolerance!: number;
    /** The flag for show geofence */
    showGeofence!: number;
    /** The auto generated id from company details master */
    companyDetailsId!: number;
    /** The auto generated id from branch details master */
    branchDetailId!: number;
    /** The color with 255 character limit */
    color!: string;

    constructor(data?: IGeofenceAddInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.companyType = _data["companyType"];
            this.branchType = _data["branchType"];
            this.name = _data["name"];
            this.geofenceData = _data["geofenceData"];
            this.shapeData = _data["shapeData"];
            this.category = _data["category"];
            this.description = _data["description"];
            this.considerForTrip = _data["considerForTrip"];
            this.fenceType = _data["fenceType"];
            this.tolerance = _data["tolerance"];
            this.showGeofence = _data["showGeofence"];
            this.companyDetailsId = _data["companyDetailsId"];
            this.branchDetailId = _data["branchDetailId"];
            this.color = _data["color"];
        }
    }

    static fromJS(data: any): GeofenceAddInput {
        data = typeof data === 'object' ? data : {};
        let result = new GeofenceAddInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["companyType"] = this.companyType;
        data["branchType"] = this.branchType;
        data["name"] = this.name;
        data["geofenceData"] = this.geofenceData;
        data["shapeData"] = this.shapeData;
        data["category"] = this.category;
        data["description"] = this.description;
        data["considerForTrip"] = this.considerForTrip;
        data["fenceType"] = this.fenceType;
        data["tolerance"] = this.tolerance;
        data["showGeofence"] = this.showGeofence;
        data["companyDetailsId"] = this.companyDetailsId;
        data["branchDetailId"] = this.branchDetailId;
        data["color"] = this.color;
        return data; 
    }
}

export interface IGeofenceAddInput {
    /** The company type should be All or Selected */
    companyType: string;
    /** The branch type should be All or Selected */
    branchType: string;
    /** The place name with 255 character limit */
    name: string;
    /** Selected geofence data */
    geofenceData: string;
    /** Selected shapeData data */
    shapeData: string;
    /** Selected category for geofence */
    category: string;
    /** The description with 5000 character limit */
    description: string;
    /** The consider for trip */
    considerForTrip: number;
    /** Fence type */
    fenceType: string;
    /** The tolerance of selected place */
    tolerance: number;
    /** The flag for show geofence */
    showGeofence: number;
    /** The auto generated id from company details master */
    companyDetailsId: number;
    /** The auto generated id from branch details master */
    branchDetailId: number;
    /** The color with 255 character limit */
    color: string;
}

