

export class CompanyDetailStatusInput implements ICompanyDetailStatusInput {
    /** The Reference id of the tbl_company_detail table */
    companyDetailId!: number;
    /** The updated status */
    status!: number;

    constructor(data?: ICompanyDetailStatusInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.companyDetailId = _data["companyDetailId"];
            this.status = _data["status"];
        }
    }

    static fromJS(data: any): CompanyDetailStatusInput {
        data = typeof data === 'object' ? data : {};
        let result = new CompanyDetailStatusInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["companyDetailId"] = this.companyDetailId;
        data["status"] = this.status;
        return data; 
    }
}

export interface ICompanyDetailStatusInput {
    /** The Reference id of the tbl_company_detail table */
    companyDetailId: number;
    /** The updated status */
    status: number;
}

