

export class PathPointDataInput implements IPathPointDataInput {
    /** The path point is coordinates for the path */
    pathPoint!: string;
    /** The selected path point lat */
    pathPointLat!: number;
    /** The selected path point long */
    pathPointLong!: number;

    constructor(data?: IPathPointDataInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.pathPoint = _data["pathPoint"];
            this.pathPointLat = _data["pathPointLat"];
            this.pathPointLong = _data["pathPointLong"];
        }
    }

    static fromJS(data: any): PathPointDataInput {
        data = typeof data === 'object' ? data : {};
        let result = new PathPointDataInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["pathPoint"] = this.pathPoint;
        data["pathPointLat"] = this.pathPointLat;
        data["pathPointLong"] = this.pathPointLong;
        return data; 
    }
}

export interface IPathPointDataInput {
    /** The path point is coordinates for the path */
    pathPoint: string;
    /** The selected path point lat */
    pathPointLat: number;
    /** The selected path point long */
    pathPointLong: number;
}

