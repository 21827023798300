import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { CommonFunctions } from '../common-functions';
import { ExpenseAddInput } from '../models/expense-add-input.model';
import { ExpenseAddOutput } from '../models/expense-add-output.model';
import { ExpenseHistoryInput } from '../models/expense-history-input.model';
import { ExpenseHistoryOutput } from '../models/expense-history-output.model';
import { ExpenseSelectAllOutput } from '../models/expense-select-all-output.model';
import { ExpenseSelectByIdOutput } from '../models/expense-select-by-id-output.model';
import { ExpenseStatusInput } from '../models/expense-status-input.model';
import { ExpenseStatusOutput } from '../models/expense-status-output.model';
import { ExpenseUpdateInput } from '../models/expense-update-input.model';
import { ExpenseUpdateOutput } from '../models/expense-update-output.model';
import { API_BASE_URL } from '../service-proxies';

@Injectable()
export class ExpenseServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(private _commonFunctions: CommonFunctions, @Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:3001/api/v1";
    }

    /**
     * @return Expense added successfully.
     */
    add(body: ExpenseAddInput): Observable<ExpenseAddOutput> {
        let url_ = this.baseUrl + "/Expense/Add";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAdd(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAdd(<any>response_);
                } catch (e) {
                    return <Observable<ExpenseAddOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ExpenseAddOutput>><any>_observableThrow(response_);
        }));
    }

    protected processAdd(response: HttpResponseBase): Observable<ExpenseAddOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ExpenseAddOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ExpenseAddOutput>(<any>null);
    }

    /**
     * @return Expense updated successfully.
     */
    update(body: ExpenseUpdateInput): Observable<ExpenseUpdateOutput> {
        let url_ = this.baseUrl + "/Expense/Update";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processUpdate(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processUpdate(<any>response_);
                } catch (e) {
                    return <Observable<ExpenseUpdateOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ExpenseUpdateOutput>><any>_observableThrow(response_);
        }));
    }

    protected processUpdate(response: HttpResponseBase): Observable<ExpenseUpdateOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ExpenseUpdateOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ExpenseUpdateOutput>(<any>null);
    }

    /**
     * @return Expense status updated successfully.
     */
    status(body: ExpenseStatusInput): Observable<ExpenseStatusOutput> {
        let url_ = this.baseUrl + "/Expense/Status";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("put", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processStatus(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processStatus(<any>response_);
                } catch (e) {
                    return <Observable<ExpenseStatusOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ExpenseStatusOutput>><any>_observableThrow(response_);
        }));
    }

    protected processStatus(response: HttpResponseBase): Observable<ExpenseStatusOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ExpenseStatusOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ExpenseStatusOutput>(<any>null);
    }

    /**
     * @return All expense selected successfully.
     */
    selectAll(): Observable<ExpenseSelectAllOutput> {
        let url_ = this.baseUrl + "/Expense/SelectAll";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectAll(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectAll(<any>response_);
                } catch (e) {
                    return <Observable<ExpenseSelectAllOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ExpenseSelectAllOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectAll(response: HttpResponseBase): Observable<ExpenseSelectAllOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ExpenseSelectAllOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ExpenseSelectAllOutput>(<any>null);
    }

    /**
     * @return Expense selected successfully.
     */
    selectById(expenseId: number): Observable<ExpenseSelectByIdOutput> {
        let url_ = this.baseUrl + "/Expense/SelectById/{expenseId}";
        if (expenseId === undefined || expenseId === null)
            throw new Error("The parameter 'expenseId' must be defined.");
        url_ = url_.replace("{expenseId}", encodeURIComponent("" + expenseId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processSelectById(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processSelectById(<any>response_);
                } catch (e) {
                    return <Observable<ExpenseSelectByIdOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ExpenseSelectByIdOutput>><any>_observableThrow(response_);
        }));
    }

    protected processSelectById(response: HttpResponseBase): Observable<ExpenseSelectByIdOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ExpenseSelectByIdOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ExpenseSelectByIdOutput>(<any>null);
    }

    /**
     * @return Expense selected successfully.
     */
    expenseHistory(body: ExpenseHistoryInput): Observable<ExpenseHistoryOutput> {
        let url_ = this.baseUrl + "/Expense/ExpenseHistory";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processExpenseHistory(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processExpenseHistory(<any>response_);
                } catch (e) {
                    return <Observable<ExpenseHistoryOutput>><any>_observableThrow(e);
                }
            } else
                return <Observable<ExpenseHistoryOutput>><any>_observableThrow(response_);
        }));
    }

    protected processExpenseHistory(response: HttpResponseBase): Observable<ExpenseHistoryOutput> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (<any>response).error instanceof Blob ? (<any>response).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = ExpenseHistoryOutput.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status !== 200 && status !== 204) {
            return this._commonFunctions.blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
            return this._commonFunctions.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf<ExpenseHistoryOutput>(<any>null);
    }
}
