import { CompanyDetailMapOutputWithSelectById } from './company-detail-map-output-with-select-by-id.model';


export class CompanyDetailSelectMapByIdOutput implements ICompanyDetailSelectMapByIdOutput {
    status!: number;
    message!: string;
    data!: CompanyDetailMapOutputWithSelectById;

    constructor(data?: ICompanyDetailSelectMapByIdOutput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.status = _data["status"];
            this.message = _data["message"];
            this.data = _data["data"] ? CompanyDetailMapOutputWithSelectById.fromJS(_data["data"]) : <any>undefined;
        }
    }

    static fromJS(data: any): CompanyDetailSelectMapByIdOutput {
        data = typeof data === 'object' ? data : {};
        let result = new CompanyDetailSelectMapByIdOutput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["status"] = this.status;
        data["message"] = this.message;
        data["data"] = this.data ? this.data.toJSON() : <any>undefined;
        return data; 
    }
}

export interface ICompanyDetailSelectMapByIdOutput {
    status: number;
    message: string;
    data: CompanyDetailMapOutputWithSelectById;
}

