

export class CompanyDetailAddMapInput implements ICompanyDetailAddMapInput {
    /** The Reference id of the tbl_company_detail table */
    companyDetailId!: number;
    /** The Reference id of the tbl_map table */
    mapId!: number;
    /** The web map key with 2048 character limit */
    webMapKey!: string;
    /** The map project id with 2048 character limit */
    mapProjectId!: string;
    /** The address from map provider flag must be one of 0 or 1 for available */
    addressFromMapProviderFlag!: number;
    /** The speed limit api must be one of 0 or 1 for available */
    speedLimitApi!: number;
    /** The speed limit api must be one of default 1 else 0 */
    default!: number;

    constructor(data?: ICompanyDetailAddMapInput) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.companyDetailId = _data["companyDetailId"];
            this.mapId = _data["mapId"];
            this.webMapKey = _data["webMapKey"];
            this.mapProjectId = _data["mapProjectId"];
            this.addressFromMapProviderFlag = _data["addressFromMapProviderFlag"];
            this.speedLimitApi = _data["speedLimitApi"];
            this.default = _data["default"];
        }
    }

    static fromJS(data: any): CompanyDetailAddMapInput {
        data = typeof data === 'object' ? data : {};
        let result = new CompanyDetailAddMapInput();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["companyDetailId"] = this.companyDetailId;
        data["mapId"] = this.mapId;
        data["webMapKey"] = this.webMapKey;
        data["mapProjectId"] = this.mapProjectId;
        data["addressFromMapProviderFlag"] = this.addressFromMapProviderFlag;
        data["speedLimitApi"] = this.speedLimitApi;
        data["default"] = this.default;
        return data; 
    }
}

export interface ICompanyDetailAddMapInput {
    /** The Reference id of the tbl_company_detail table */
    companyDetailId: number;
    /** The Reference id of the tbl_map table */
    mapId: number;
    /** The web map key with 2048 character limit */
    webMapKey: string;
    /** The map project id with 2048 character limit */
    mapProjectId: string;
    /** The address from map provider flag must be one of 0 or 1 for available */
    addressFromMapProviderFlag: number;
    /** The speed limit api must be one of 0 or 1 for available */
    speedLimitApi: number;
    /** The speed limit api must be one of default 1 else 0 */
    default: number;
}

